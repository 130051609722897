import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'twilio-video';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import {translate} from 'translations';
import Share from './Share';
import {trackEvent} from 'utils/tracking';

const styles = (theme) => ({
  enabled: {
    color: 'green',
  },
  disabled: {
    color: theme.palette.error.main,
  },
  container: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  rest: {
    flex: '0 1 auto',
  },
  flex: {
    flex: '1 1 auto',
    overflow: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  leave: {
    color: theme.palette.error.main,
  },
});

class VideoComponent extends Component {
  participantContainers = {}
  connectedOnce = false

  constructor(props) {
    super(props);
    this.state = {
      localTracks: {},
      activeRoom: null,
      audio: true,
      video: true,
    };
  }

  componentDidMount() {
    this.joinRoom();
  }

  joinRoom = () => {
    const {token, board} = this.props;
    const connectOptions = {
      name: board,
      preferredAudioCodecs: ['isac'],
      preferredVideoCodecs: ['H264'],
    };
    connect(token, connectOptions)
        .then(this.roomJoined, (error) => {
          setTimeout(this.joinRoom, 1000);
        });
  }

  clear = () => {
    this.refs.localMedia.innerHTML = "";
    this.refs.remoteMedia.innerHTML = "";
    this.participantContainers = {};
  }

  roomJoined = (room) => {
    trackEvent('board.room.joined');
    this.clear();
    // Attach LocalParticipant's tracks to the DOM, if not already attached.
    const previewContainer = this.refs.localMedia;
    if (!previewContainer.querySelector('video')) {
      this.attachParticipantTracks(room.localParticipant, previewContainer, true);
    }
    // Attach the Tracks of the room's participants.
    room.participants.forEach((participant) => {
      const previewContainer = this.refs.remoteMedia;
      this.attachParticipantTracks(participant, previewContainer);
    });

    // Participant joining room
    room.on('participantConnected', (participant) => {
    });

    // Attach participant’s tracks to DOM when they add a track
    room.on('trackAdded', (track, participant) => {
      const previewContainer = this.refs.remoteMedia;
      this.attachTracks([track], previewContainer, participant);
    });

    // Detach participant’s track from DOM when they remove a track.
    room.on('trackRemoved', (track, participant) => {
      this.detachTracks([track]);
    });

    // Detach all participant’s track when they leave a room.
    room.on('participantDisconnected', (participant) => {
      this.detachParticipantTracks(participant);
      if (this.participantContainers[participant.sid]) {
        this.participantContainers[participant.sid].remove();
      }
    });

    // Once the local participant leaves the room, detach the Tracks
    // of all other participants, including that of the LocalParticipant.
    room.on('disconnected', () => {
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.setState({
        activeRoom: null,
      });
      this.clear();
      this.joinRoom();
    });

    // Called when a participant joins a room
    this.setState({
      activeRoom: room,
    }, () => {
      if (!this.connectedOnce || !this.state.audio) {
        this.toggleLocalTrack('audio');
      }
      if (!this.connectedOnce || !this.state.video) {
        this.toggleLocalTrack('video');
      }
      this.connectedOnce = true;
    });
  }

  getLocalTracks = () => {
    const {activeRoom} = this.state;
    if (!activeRoom) {
      return null;
    }
    const {localParticipant} = activeRoom;
    if (!localParticipant) {
      return null;
    }
    return localParticipant.tracks;
  }

  toggleLocalTrack = (kind) => {
    const localTracks = this.getLocalTracks();
    if (!localTracks) {
      return null;
    }
    localTracks.forEach((track) => {
      if (track.kind === kind) {
        if (track.isEnabled) {
          trackEvent(`board.room.${kind}.disabled`);
          track.element.setAttribute('data-enabled', false);
          track.disable();
          this.setState({[kind]: false});
        } else {
          trackEvent(`board.room.${kind}.enabled`);
          track.element.setAttribute('data-enabled', true);
          track.enable();
          this.setState({[kind]: true});
        }
      }
    });
  }

  // Attach the Tracks to the DOM.
  attachTracks = (tracks, container, participant, flip) => {
    let participantContainer = this.participantContainers[participant.sid];
    if (!participantContainer) {
      participantContainer = document.createElement('div');
      participantContainer.id = participant.sid;
      participantContainer.className = 'participant-container';
      container.appendChild(participantContainer);
      this.participantContainers[participant.sid] = participantContainer;
    }

    tracks.forEach((track) => {
      track.element = track.attach();
      track.element.setAttribute('data-identity', participant.identity);
      track.element.setAttribute('data-enabled', track.isEnabled);
      participantContainer.appendChild(track.element);
      if (track.kind === 'audio') {
        const participantName = document.createElement('div');
        participantName.className = 'participant-name';
        participantName.innerHTML = `${participant.identity}<i class="fas fa-microphone"></i><i class="fas fa-microphone-slash"></i>`;
        participantContainer.appendChild(participantName);
      }
      if (track.kind === 'video') {
        if (flip) {
          track.element.style.transform = 'scale(-1, 1)';
        }
        const placeholder = document.createElement('div');
        placeholder.className = 'placeholder';
        placeholder.innerHTML = '<i class="fas fa-video-slash"></i>';
        participantContainer.appendChild(placeholder);
      }
      track.on('enabled', (a) => {
        track.element.setAttribute('data-enabled', true);
      });
      track.on('disabled', (a) => {
        track.element.setAttribute('data-enabled', false);
      });
    });
  }

  // Attach the Participant's Tracks to the DOM.
  attachParticipantTracks = (participant, container, flip) => {
    const tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container, participant, flip);
  }

  detachTracks = (tracks) => {
    tracks.forEach((track) => {
      track.detach().forEach((detachedElement) => {
        detachedElement.remove();
      });
    });
  }

  detachParticipantTracks = (participant) => {
    const tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  }

  renderLocalMedia = () => {
    const {classes, board} = this.props;
    return (
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <div ref="localMedia"> </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {
              !!this.state.activeRoom && (
                <Fragment>
                  <Grid item xs={6} sm={4}>
                    <Button className={this.state.audio ? classes.enabled : classes.disabled} variant="contained" color="primary" fullWidth size="large" onClick={() => this.toggleLocalTrack('audio')}>
                      {this.state.audio ? <MicIcon /> : <MicOffIcon />}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Button className={this.state.video ? classes.enabled : classes.disabled} variant="contained" color="primary" fullWidth size="large" onClick={() => this.toggleLocalTrack('video')}>
                      {this.state.video ? <VideocamIcon /> : <VideocamOffIcon />}
                    </Button>
                  </Grid>
                </Fragment>
              )
            }
            <Grid item xs={12} sm={!!this.state.activeRoom ? 4 : 12}>
              <Share board={board} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {classes, board, isExpanded, toggleIsExpanded} = this.props;
    return (
      <div className={classes.container}>
        <Grid spacing={3} container>
          <Grid item xs={9}>
            <Button className={classes.leave} href="/" variant="contained" color="primary" size="large" fullWidth>
              {translate('leave-room', board)}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={toggleIsExpanded} variant="contained" color="primary" size="large" fullWidth>
              {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={isExpanded ? 3 : 12}>
            {this.renderLocalMedia()}
          </Grid>
          <Grid item xs={6} sm={6} md={isExpanded ? 9 : 12} className={`remote-media`} ref="remoteMedia">
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(VideoComponent);
