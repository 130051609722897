import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Layover from './Layover';

const styles = (theme) => ({
  textField: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'grab',
    border: 'none',
    maxWidth: '20%',
    fontFamily: 'AvenirNext',
  },
  input: {
    fontSize: 'inherit',
  },
});

class TextLayover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSize: 20,
      text: '',
    };
  }

  componentDidMount() {
    window.input = this.refs.input;
    setTimeout(this.focusInput, 2000);
  }

  focusInput = () => this.refs.input && this.refs.input.focus();

  onSliderChange = (event, fontSize) => this.setState({fontSize});

  onInputChange = (event) => this.setState({text: event.target.value});

  onSave = (coordinates) => {
    if (!this.state.text) {
      return;
    }
    this.props.onSave({
      coordinates,
      color: this.props.color,
      ...this.state,
    });
  }

  render = () => {
    const {classes, onCancel, zoom, coordinates, color, top, left} = this.props;
    const {fontSize} = this.state;
    return (
      <Layover
        onCancel={onCancel}
        onSave={this.onSave}
        zoom={zoom}
        sliderValue={fontSize}
        coordinates={coordinates}
        onSliderChange={this.onSliderChange}
        sliderMin={5}
        sliderMax={40}
        top={top}
        left={left}
      >
        <Input
          autoFocus
          ref="input"
          fullWidth
          id="draggable-text"
          label=""
          className={`handle ${classes.textField}`}
          style={{fontSize, color: color}}
          onChange={this.onInputChange}
        />
      </Layover>
    );
  }
}

export default withStyles(styles)(TextLayover);
