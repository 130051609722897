import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import {detectShape} from 'utils/geometry';
import drawImage from './drawImage';
import {keys, forEach, filter, concat, uniq} from 'lodash';

function midPointBtw(p1, p2) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

const canvasStyle = {
  display: 'block',
  position: 'absolute',
};

const canvasTypes = [
  {
    name: 'interface',
    zIndex: 15,
  },
  {
    name: 'drawing',
    zIndex: 11,
  },
  {
    name: 'temp',
    zIndex: 12,
  },
  {
    name: 'text',
    zIndex: 10,
  },
  {
    name: 'grid',
    zIndex: 9,
  },
  {
    name: 'lines',
    zIndex: 9,
  },
];

const dimensionsPropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

export default class extends PureComponent {
  static propTypes = {
    loadTimeOffset: PropTypes.number,
    lazyRadius: PropTypes.number,
    brushRadius: PropTypes.number,
    brushColor: PropTypes.string,
    gridColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    hideGrid: PropTypes.bool,
    canvasWidth: dimensionsPropTypes,
    canvasHeight: dimensionsPropTypes,
    disabled: PropTypes.bool,
    imgSrc: PropTypes.string,
    saveData: PropTypes.string,
    immediateLoading: PropTypes.bool,
    onLineAdd: PropTypes.func,
  };

  static defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 12,
    brushRadius: 10,
    brushColor: '#444',
    gridColor: 'rgba(150,150,150,0.17)',
    backgroundColor: '#FFF',
    hideGrid: false,
    canvasWidth: 400,
    canvasHeight: 400,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    onLineAdd: () => {},
  };

  constructor(props) {
    super(props);

    this.canvas = {};
    this.ctx = {};

    this.points = [];
    this.lines = [];

    this.isDrawing = false;
  }

  componentDidMount() {
    this.canvasObserver = new ResizeObserver((entries, observer) =>
      this.handleCanvasResize(entries, observer),
    );
    this.canvasObserver.observe(this.canvasContainer);
  }

  componentWillUnmount = () => {
    this.canvasObserver.unobserve(this.canvasContainer);
  }

  drawnImages = [];
  drawnLines = {};
  deletedLines = [];
  saveData = {};

  drawImages = (images) => {
    const imagesToDraw = images || this.images;
    if (!imagesToDraw) {
      return;
    }

    this.images = imagesToDraw;
    const _images = [...imagesToDraw];

    const loadImages = () => {
      if (!_images.length) {
        return;
      }
      const image = _images.shift();
      const imgUrl = image.src.split('?')[0];
      if (this.drawnImages.indexOf(imgUrl) === -1) {
        this.drawnImages.push(imgUrl);
        const img = new Image();
        img.src = image.src;
        img.onload = () => {
          drawImage({
            ctx: this.ctx.grid,
            img: img,
            ...image.frame,
          });
          loadImages();
        };
      } else {
        loadImages();
      }
    };
    loadImages();
  };

  drawnTexts = [];

  drawTexts = (texts) => {
    forEach(texts, text => {
      if (this.drawnTexts[text.id]) {
        return;
      }
      this.drawnTexts[text.id] = text;
      this.ctx['text'].fillStyle = text.color;
      this.ctx['text'].font = `${text.fontSize} ${text.fontFamily}`;
      this.ctx['text'].fillText(text.text, text.frame.x, text.frame.y);
    });
  }

  undo = () => {
    const lines = this.lines.slice(0, -1);
    this.clear();
    this.simulateDrawingLines({lines, immediate: true});
  };

  getSaveData = () => {
    // Construct and return the stringified saveData object
    return {
      lines: this.lines,
      width: this.props.canvasWidth,
      height: this.props.canvasHeight,
    };
  };


  checkIfNeedsClear = (lines, drawnLines) => {
    if (!drawnLines) {
      return;
    }
    var lineExists = true;
    const newLines = {};
    for(let i=0; i<lines.length; i++) {
      newLines[lines[i].id] = lines[i];
    };
    const drawnLineIds = keys(drawnLines);
    for(let i=0; i<drawnLineIds.length; i++) {
      if (!newLines[drawnLineIds[i]]) {
        lineExists = false;
        break;
      }
    };
    if (!lineExists) {
      this.needClear = true;
    }
  }

  loadSaveData = (saveData = this.saveData, immediate = this.props.immediateLoading) => {
    this.saveData = saveData;
    let {lines} = saveData;
    if (!lines || typeof lines.push !== 'function') {
      throw new Error('saveData.lines needs to be an array!');
    }
    lines = filter(lines, line => this.deletedLines.indexOf(line.id) === -1);

    this.checkIfNeedsClear(lines, this.drawnLines);
    if (this.needClear) {
      this.clear();
      this.needClear = false;
    }
    this.simulateDrawingLines({
      lines,
      immediate,
    });
  };

  simulateDrawingLines = ({lines, immediate}) => {
    // Simulate live-drawing of the loaded lines
    // TODO use a generator
    let curTime = 0;
    const timeoutGap = immediate ? 0 : this.props.loadTimeOffset;

    lines.forEach((line) => {
      if (this.drawnLines[line.id]) {
        return;
      }
      this.drawnLines[line.id] = line;
      this.drawLine(line, curTime, immediate, timeoutGap);
      curTime += timeoutGap * line.points.length;
    });
  };

  drawLine = (line, curTime, immediate, timeoutGap) => {
    const {points, brushColor, brushRadius, isDotted} = line;
    this.drawPoints({
      points,
      brushColor,
      brushRadius,
      isDotted,
    });
    this.saveLine({brushColor, brushRadius, isDotted}, points);
  };

  handleTouchStart = (e) => {
    this.handleMouseDown(e);
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    const {x, y} = this.getPointerPos(e);
    this.handlePointerMove(x, y);
  };

  handleTouchEnd = (e) => {
    this.handleMouseUp(e);
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    this.isDrawing = true;
    this.handleMouseMove(e);
  };

  handleMouseMove = (e) => {
    const {x, y} = this.getPointerPos(e);
    this.handlePointerMove(x, y);
  };

  handleMouseUp = (e) => {
    e.preventDefault();
    this.isDrawing = false;

    this.saveLine({isNew: true}, this.points);
    this.points = [];
  };

  handleCanvasResize = (entries, observer) => {
    for (const entry of entries) {
      const {width, height} = entry.contentRect;
      this.setCanvasSize(this.canvas.interface, width, height);
      this.setCanvasSize(this.canvas.drawing, width, height);
      this.setCanvasSize(this.canvas.temp, width, height);
      this.setCanvasSize(this.canvas.grid, width, height);
      this.setCanvasSize(this.canvas.text, width, height);
      this.setCanvasSize(this.canvas.lines, width, height);

      this.drawGrid(!!this.hideGrid);
    }
  };

  setCanvasSize = (canvas, width, height) => {
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = width;
    canvas.style.height = height;
  };

  getPointerPos = (e) => {
    const {zoom} = this.props;
    const rect = this.canvas.interface.getBoundingClientRect();

    // use cursor pos as default
    let clientX = e.clientX;
    let clientY = e.clientY;

    // use first touch if available
    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    // return mouse/touch position inside canvas
    return {
      x: (clientX - rect.left) / zoom,
      y: (clientY - rect.top) / zoom,
    };
  };

  handlePointerMove = (x, y) => {
    if (this.props.disabled) return;

    if (this.isDrawing) {
      // Add new point
      if (this.props.brushColor.indexOf(',0)') !== -1) {
        const idsToDelete = this.props.onErasePoint({x, y});
        this.deletedLines = uniq(concat(this.deletedLines, idsToDelete));
        this.loadSaveData();
      }
      this.points.push({x, y});

      // Draw current points
      if (this.points.length) {
        this.drawPoints({
          points: this.points,
          brushColor: this.props.brushColor,
          brushRadius: this.props.brushRadius,
          isDotted: this.props.isDotted,
        });
      }
    }
  };

  drawPoints = ({points, brushColor, brushRadius, isDotted}) => {
    this.ctx.temp.lineJoin = 'round';
    this.ctx.temp.lineCap = 'round';
    this.ctx.temp.strokeStyle = brushColor;

    this.ctx.temp.clearRect(
        0,
        0,
        this.ctx.temp.canvas.width,
        this.ctx.temp.canvas.height,
    );
    this.ctx.temp.lineWidth = brushRadius * 2;

    let p1 = points[0] || {};
    let p2 = points[1] || {};

    this.ctx.temp.moveTo(p2.x, p2.y);
    this.ctx.temp.beginPath();
    if (isDotted) {
      this.ctx.temp.setLineDash([10, 10]);
    } else {
      this.ctx.temp.setLineDash([]);
    }

    for (let i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      const midPoint = midPointBtw(p1, p2);
      this.ctx.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
      p1 = points[i];
      p2 = points[i + 1];
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    this.ctx.temp.lineTo(p1.x, p1.y);
    this.ctx.temp.stroke();
  };

  saveLine = async ({brushColor, brushRadius, isNew, isDotted} = {}, points) => {
    if (points.length === 0) return;

    // Save as new line
    const line = {
      points: [...points],
      brushColor: brushColor || this.props.brushColor,
      brushRadius: brushRadius || this.props.brushRadius,
      isDotted: isDotted || this.props.isDotted,
    };
    this.lines.push(line);

    if (isNew) {
      if (this.props.isShape) {
        line.points = detectShape(line.points);
      }
    }

    const width = this.canvas.temp.width;
    const height = this.canvas.temp.height;

    // Copy the line to the drawing canvas
    this.ctx.drawing.drawImage(this.canvas.temp, 0, 0, width, height);

    // Clear the temporary line-drawing canvas
    this.ctx.temp.clearRect(0, 0, width, height);
    if (isNew) {
      const id = await this.props.onLineAdd(line);
      if (!this.props.isShape) {
        this.drawnLines[id] = {
          ...line,
          id,
        }
      } else {
        this.needClear = true;
      }
    }
  };

  clear = (clearImages) => {
    this.drawnLines = {};
    this.lines = [];
    this.ctx.drawing.clearRect(
        0,
        0,
        this.canvas.drawing.width,
        this.canvas.drawing.height,
    );
    this.ctx.temp.clearRect(
        0,
        0,
        this.canvas.temp.width,
        this.canvas.temp.height,
    );
    if (clearImages) {
      this.drawnImages = [];
      this.ctx.grid.clearRect(
          0,
          0,
          this.canvas.temp.width,
          this.canvas.temp.height,
      );
      this.drawnTexts = [];
      this.ctx.text.clearRect(
          0,
          0,
          this.canvas.temp.width,
          this.canvas.temp.height,
      );
    }
  };

  drawGrid = (hideGrid) => {
    this.hideGrid = hideGrid;

    var ctx = this.ctx.lines;
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (this.hideGrid) {
      return;
    }

    ctx.beginPath();
    ctx.setLineDash([5, 1]);
    ctx.setLineDash([]);
    ctx.strokeStyle = this.props.gridColor;
    ctx.lineWidth = 0.5;

    const gridSize = 50;

    let countX = 0;
    while (countX < ctx.canvas.width) {
      countX += gridSize;
      ctx.moveTo(countX, 0);
      ctx.lineTo(countX, ctx.canvas.height);
    }
    ctx.stroke();

    let countY = 0;
    while (countY < ctx.canvas.height) {
      countY += gridSize;
      ctx.moveTo(0, countY);
      ctx.lineTo(ctx.canvas.width, countY);
    }
    ctx.stroke();
  };

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          display: 'block',
          background: this.props.backgroundColor,
          touchAction: 'none',
          width: this.props.canvasWidth,
          height: this.props.canvasHeight,
          ...this.props.style,
        }}
        ref={(container) => {
          if (container) {
            this.canvasContainer = container;
          }
        }}
      >
        {canvasTypes.map(({name, zIndex}) => {
          const isInterface = name === 'interface';
          return (
            <canvas
              key={name}
              ref={(canvas) => {
                if (canvas) {
                  this.canvas[name] = canvas;
                  this.ctx[name] = canvas.getContext('2d');
                }
              }}
              style={{...canvasStyle, zIndex}}
              onMouseDown={isInterface ? this.handleMouseDown : undefined}
              onMouseMove={isInterface ? this.handleMouseMove : undefined}
              onMouseUp={isInterface ? this.handleMouseUp : undefined}
              onMouseOut={isInterface ? this.handleMouseUp : undefined}
              onTouchStart={isInterface ? this.handleTouchStart : undefined}
              onTouchMove={isInterface ? this.handleTouchMove : undefined}
              onTouchEnd={isInterface ? this.handleTouchEnd : undefined}
              onTouchCancel={isInterface ? this.handleTouchEnd : undefined}
            />
          );
        })}
      </div>
    );
  }
}
