import React, {Component} from 'react';
import {getVideoToken} from 'utils/twilio';
import Video from './Video';

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoToken: '',
    };
  }

  componentDidMount() {
    this.loadVideoToken();
  }

  loadVideoToken = async () => {
    const {firebase, board, name} = this.props;
    const videoToken = await getVideoToken(firebase, board, name);
    this.setState({videoToken});
  }

  render = () => {
    const {videoToken} = this.state;
    const {board, name, isExpanded, toggleIsExpanded} = this.props;
    if (!videoToken) {
      return null;
    }

    return (
      <Video token={videoToken} board={board} name={name} isExpanded={isExpanded} toggleIsExpanded={toggleIsExpanded} />
    );
  }
}

export default Room;
