import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DialogActions from '@material-ui/core/DialogActions';
import {keys, values} from 'lodash';
import IndividualBoard from 'components/Board/IndividualBoard';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {translate} from 'translations';
import {trackEvent} from 'utils/tracking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  canvasWrapper: {
    height: '300px',
    maxHeight: '50vh',
    width: '500px',
    maxWidth: '70vw',
    padding: 0,
    margin: `0 0 20px 0`,
  },
  canvasStyles: {
    width: '100%',
    height: '300px',
    maxHeight: '50vh',
    border: 'solid 1px #666',
    margin: 0,
    padding: 0,
  },
  clear: {
    color: 'red',
  },
};

class Switcher extends Component {
  state = {
    loading: true,
    data: {},
  }

  getPagination = (pages, activePage) => {
    const pagesArr = values(pages);
    let prev = null;
    let next = null;
    let index = 1;
    for (let i=0; i<pagesArr.length; i++) {
      const currentPage = pagesArr[i];
      if (currentPage.pageId === activePage.pageId) {
        index = i;
        if (i > 0) {
          prev = pagesArr[i-1];
        }
        if (i < pagesArr.length - 1) {
          next = pagesArr[i+1];
        }
      }
    }
    return {prev, next, index};
  }

  render = () => {
    const {
      open,
      handleClose,
      pages,
      activePage,
      firebase,
      changeActivePage,
      addNewPage,
      clearBoard,
    } = this.props;
    const pageIds = keys(pages);
    const maxSteps = pageIds.length;
    const {pagesPath, pageId} = activePage;
    const path = `${pagesPath.toLowerCase()}/${pageId.toLowerCase()}`;
    const pagination = this.getPagination(pages, activePage);
    const onChangePage = (index, key) => {
      trackEvent(`modal.switcher.${key}`);
      changeActivePage(index);
    };
    const onClearBoard = () => {
      trackEvent('modal.switcher.clear');
      clearBoard();
    };
    const onAddNewPage = () => {
      trackEvent('modal.switcher.addNew');
      addNewPage();
    };
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={styles.canvasWrapper}>
              {
                !!open && (
                  <IndividualBoard
                    key={`${path}-${Boolean(open).toString()}`}
                    showControls={false}
                    canvasStyle={styles.canvasStyles}
                    firebase={firebase}
                    path={path}
                  />
                )
              }
            </div>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={pagination.index}
              nextButton={
                <Button
                  size="small"
                  onClick={() => onChangePage(pagination.next, 'next')}
                  disabled={!pagination.next}
                >
                  {translate('next')}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={() => onChangePage(pagination.prev, 'prev')}
                  disabled={!pagination.prev}
                >
                  <KeyboardArrowLeft />
                  {translate('back')}
                </Button>
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClearBoard} color="primary" style={styles.clear} startIcon={<DeleteIcon />}>
            {translate('clear-board')}
          </Button>
          <Button onClick={onAddNewPage} color="primary" variant="outlined" startIcon={<AddIcon />}>
            {translate('add-new-board')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Switcher;
