import {generateName} from 'utils/name';

export const createNewBoard = async (firebase) => {
  const name = generateName();
  const activePage = {
    pageId: name.toLowerCase(),
    pagesPath: 'documents',
  };
  const documentRef = firebase.database().ref(`/documents/${activePage.pageId}`);

  await documentRef.set({
    activePage,
    pages: {},
    strokes: {},
    images: {},
  });
  await firebase.database().ref(`/documents/${activePage.pageId}/pages`).push(activePage);
  return name;
};
