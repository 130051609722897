export const getVideoToken = async (firebase, room, userId) => {
  const getVideoTokenFunc = firebase.functions().httpsCallable('getVideoToken');
  const result = await getVideoTokenFunc({
    room: room.toLowerCase(),
    userId: userId,
  });
  if (result && result.data) {
    return result.data.token;
  }
  return null;
};
