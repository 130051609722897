export const black = {
  id: 'black',
  value: 'black',
  color: '0,0,0',
};
export const grey = {
  id: 'grey',
  value: 'grey',
  color: '127,127,127',
};
export const brown = {
  id: 'brown',
  value: 'brown',
  color: '139,87,64',
};
export const red = {
  id: 'red',
  value: 'red',
  color: '215,58,49',
};
export const orange = {
  id: 'orange',
  value: 'orange',
  color: '247,110,60',
};
export const yellow = {
  id: 'yellow',
  value: 'yellow',
  color: '247,216,66',
};

export const green = {
  id: 'green',
  value: 'green',
  color: '88,183,96',
};

export const turquoise = {
  id: 'turquoise',
  value: 'turquoise',
  color: '90,225,195',
};

export const blue = {
  id: 'blue',
  value: 'blue',
  color: '84,129,230',
};

export const purple = {
  id: 'purple',
  value: 'purple',
  color: '145,60,205',
};

export const pink = {
  id: 'pink',
  value: 'pink',
  color: '255,20,93',
};

export const white = {
  id: 'white',
  value: 'white',
  color: '255,255,255',
};

export default [
  black,
  grey,
  brown,
  red,
  orange,
  yellow,
  green,
  turquoise,
  blue,
  purple,
];
