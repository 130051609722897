import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import sprink1 from 'images/sprink1.png';
import sprink2 from 'images/sprink2.png';
import sprink3 from 'images/sprink3.png';
import logo from 'images/logo.png';
import slack from 'images/slack.png';
import slackLogo from 'images/slack-logo.png';
import icon1 from 'images/icon1.png';
import icon2 from 'images/icon2.png';
import icon3 from 'images/icon3.png';
import fb from 'images/fb.png';
import twitter from 'images/twitter.png';
import Links from 'components/Links';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
  },
  margin: {
    margin: 10,
    display: 'block',
  },
  greyContainer: {
    backgroundColor: '#D9DEE1',
  },
  icon: {
    borderRadius: '50%',
    height: 80,
    width: 80,
    padding: 20,
    margin: 20,
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  iconSmall: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    backgroundColor: '#D9DEE1',
    padding: 15,
    margin: '0 auto',
    display: 'inline-block',
  },
  socialImage: {
    maxWidth: '100%',
  },
  fullHeight: {
    minHeight: '100vh',
    padding: '10vh 20px',
  },
  content: {
    margin: '0 auto',
    maxWidth: 1300,
    paddingLeft: 40,
    paddingRight: 40,
  },
  image: {
    maxWidth: 700,
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    maxWidth: 400,
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    }
  },
  slackLogo: {
    maxWidth: 300,
    width: '80%',
    margin: '30px 0 50px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    }
  },
  logoSmall: {
    maxWidth: 300,
    width: '80%',
  },
  smallImage: {
    maxWidth: 700,
    width: '90%',
    margin: '5%',
  },
  section2: {
    textAlign: 'center',
    padding: '5%',
    maxWidth: 800,
    margin: '0 auto',
  },
  section3Wrapper: {
    backgroundColor: '#081b3a',
  },
  section3: {
    textAlign: 'center',
    padding: '5%',
    maxWidth: 800,
    margin: '0 auto',
  },
  section4: {
    textAlign: 'center',
    padding: '5%',
    maxWidth: 800,
    margin: '0 auto',
  },
}));

const HomeView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.greyContainer}>
        <div className={classes.content}>
          <Grid className={classes.fullHeight} container justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
              <img className={classes.image} src={sprink1} alt="basic screenshot frame" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img className={classes.logo} src={logo} alt="logo" />
              <Typography className={classes.margin} color="primary" variant="h5">
                Enterprise Whiteboarding for Distributed Teams
              </Typography>
              <Typography className={classes.margin}>
              <Links />
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.content}>
        <Grid className={classes.fullHeight} container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.margin} variant="h4">
              BUILT FOR
            </Typography>
            <Typography variant="h4">
              <strong>
                COLLABORATION
              </strong>
            </Typography>
            <img className={classes.image} src={sprink2} alt="board" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={4}>
                <img className={classes.icon} src={icon1} alt="icon1"/>
                <Typography>
                  Create a multi-party collaborative whiteboarding session with real time audio and video
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <img className={classes.icon} src={icon2} alt="icon2"/>
                <Typography>
                  Add images and select from a wide variety of brush tools to effectively communicate your ideas
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <img className={classes.icon} src={icon3} alt="icon3"/>
                <Typography>
                  Don’t worry about being messy, Sprink can convert your doodles into perfect shapes so you can focus on being creative
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.greyContainer}>
        <div className={classes.content}>
          <Grid spacing={10} className={classes.fullHeight} container justify="center" alignItems="center">
            <Grid item xs={12}>
              <img className={classes.image} src={sprink3} alt="sprink3" />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.margin} color="primary" variant="h4">
                    EASY & CONVENIENT
                  </Typography>
                  <Typography className={classes.margin} color="primary">
                    There’s no need to register for an account allowing you to seamlessly start collaborating whenever and wherever - simply create a meeting and share the link with your friends and colleagues to join your board
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.margin} color="primary" variant="h4">
                    ACCESSIBLE ANYWHERE
                  </Typography>
                  <Typography>
                    Use Sprink on your phone, computer, iPad or tablet wherever you are and with full accessibility to the board through your devices, you can drop ideas down whether you're at the coffee shop or gym
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.content}>
        <Grid className={classes.fullHeight} container justify="center" alignItems="center">
          <Grid item xs={12}>
            <img className={classes.slackLogo}  src={slackLogo} alt="slack-logo" />
            <Typography className={classes.margin} variant="h3">
              <strong>
                SLACK INTEGRATION
              </strong>
            </Typography>
            <br /><br /><br /><br /><br />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.margin} variant="h4">
              SPRINK WITH SLACK
            </Typography>
            <Typography className={`${classes.margin} ${classes.image}`} variant="body1">
              The Sprink App lets you whiteboard with your team while on video conference. This integration lets you easily start a video conference with your channel, group, or direct message. Whiteboard with your team on the iOS app or follow along on your browser. Once the meeting is over automatically get a snapshot of your whiteboard back in slack.
            </Typography>
            <br /><br /><br /><br /><br />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.left}>
            <Typography className={classes.margin} variant="h4">
              GET STARTED
            </Typography>
            <Typography className={classes.margin} variant="body1">
              To get started enter /sprink to receive a link that will start the video conference on the Sprink iOS app, or let you view the live whiteboard on trysprink.com. Download the Sprink App to join the whiteboard.
            </Typography>
            <br />
            <br />
            <Typography className={classes.margin} variant="h4">
              /sprink
            </Typography>
            <Typography className={classes.margin} variant="body1">
              A new whiteboard will be generated for you
            </Typography>
            <br />
            <br />
            <Typography className={classes.margin} variant="h4">
              /sprink image [room name]
            </Typography>
            <Typography className={classes.margin} variant="body1">
              On demand screenshot of the whiteboard posted in your slack channel.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <img className={classes.logo}  src={slack} alt="slack" />
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <Grid className={classes.fullHeight} container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.margin} variant="h4">
              Questions?
            </Typography>
            <Typography className={classes.margin} variant="h4">
              <strong>Contact Us</strong>
            </Typography>
            <Typography className={classes.margin} variant="h6">
              <a href="mailto:inbound@trysprink.com">inbound@trysprink.com</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img className={classes.logoSmall} src={logo} alt="logo" />
              </Grid>
              <Grid className={classes.right} item xs={6}>
                <a className={classes.iconSmall} href="https://www.facebook.com/trysprink">
                  <img  class={classes.socialImage} src={fb} alt="fb"/>
                </a>
              </Grid>
              <Grid className={classes.left} item xs={6}>
                <a className={classes.iconSmall} href="https://twitter.com/trysprink">
                  <img  class={classes.socialImage} src={twitter} alt="twitter"/>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.left}>
          <Button  href="/terms" size="large">Terms</Button>
          <Button  href="/privacy" size="large">Privacy</Button>
        </div>
      </div>
    </div>
  );
};
export default HomeView;
