import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import logo from 'images/logo.png';
import Links from './Links';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 50,
  },
}));

export default function Header() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:800px)');
  return (
    <div className={classes.root}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <div variant="h6" className={classes.title}>
            <Button
              href="/"
              size="large"
            >
              <img src={logo} alt="logo" className={classes.logo} />
            </Button>
          </div>
          { !!matches && <Links /> }
        </Toolbar>
      </AppBar>
    </div>
  )
}
