import {createMuiTheme} from '@material-ui/core/styles';

function lightenDarkenColor(col, amt) {
  let usePound = false;
  if ( col[0] === '#' ) {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if ( r > 255 ) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if ( b > 255 ) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if ( g > 255 ) g = 255;
  else if ( g < 0 ) g = 0;

  return (usePound?'#':'') + (g | (b << 8) | (r << 16)).toString(16);
}

const createPallete = (color) => ({
  50: lightenDarkenColor(color, 50),
  100: lightenDarkenColor(color, 40),
  200: lightenDarkenColor(color, 30),
  300: lightenDarkenColor(color, 20),
  400: lightenDarkenColor(color, 10),
  500: lightenDarkenColor(color, 0),
  600: lightenDarkenColor(color, -10),
  700: lightenDarkenColor(color, -20),
  800: lightenDarkenColor(color, -30),
  900: lightenDarkenColor(color, -40),
  A100: lightenDarkenColor(color, 30),
  A200: lightenDarkenColor(color, 10),
  A400: lightenDarkenColor(color, 50),
  A700: lightenDarkenColor(color, -10),
});

const theme = createMuiTheme({
  palette: {
    primary: createPallete('#333333'),
    secondary: createPallete('#DDDDDD'),
  },
  status: {
    danger: 'orange',
  },
});

export default theme;
