import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Canvas from 'components/Canvas';
import NotFound from 'components/NotFound';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import {debounce, forEach, values, map} from 'lodash';
import {getUserName} from 'utils/storage';
import {getPointBlock} from 'utils/canvas';
import {trackEvent} from 'utils/tracking';
import {translate} from 'translations';

const styles = (theme) => ({
  topRight: {
    position: 'absolute',
    top: 30,
    right: 30,
    zIndex: 20,
    maxWidth: 100,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      top: 20,
      right: 20,
    },
  },
});

class IndividualBoard extends Component {
  constructor(props) {
    super(props);

    this.deletedStrokes = [];
    this.state = {
      data: {
        images: {},
        strokes: {},
        textBlocks: {},
      },
      hideGrid: false,
      zoom: 1,
      undo: [],
      redo: [],
    };
    this.debouncedGetSnapshot = debounce(this.getSnapshot, 150);
    this.knownStrokes = {};
    this.grid = {};
  }

  toggleGrid = () => this.setState({hideGrid: !this.state.hideGrid})

  zoomIn = () => {
    trackEvent('board.canvas.zoomIn');
    this.setState({zoom: 1});
  }

  zoomOut = () => {
    trackEvent('board.canvas.zoomOut');
    this.setState({zoom: 0.5});
  }

  componentDidMount = () => {
    const {path, firebase} = this.props;
    const strokesRef = firebase.database()
        .ref(`/${path}/strokes`);

    this.strokeChildAdded = strokesRef.on('child_added', (snapshot) => {
      if (this.knownStrokes[snapshot.key]) {
        return;
      }
      this.debouncedGetSnapshot();
    });
    this.strokeChildChanged = strokesRef.on('child_changed', this.debouncedGetSnapshot);
    this.strokeChildRemoved = strokesRef.on('child_removed', (snapshot) => {
      if (this.deletedStrokes.indexOf(snapshot.key) !== -1) {
        return;
      }
      this.debouncedGetSnapshot();
    });

    const imagesRef = firebase.database()
        .ref(`/${path}/images`);

    this.imageChildAdded = imagesRef.on('child_added', this.debouncedGetSnapshot);
    this.imageChildChanged = imagesRef.on('child_changed', this.debouncedGetSnapshot);
    this.imageChildRemoved = imagesRef.on('child_removed', this.debouncedGetSnapshot);

    const textBlocksRef = firebase.database()
        .ref(`/${path}/textBlocks`);

    this.textBlocksChildAdded = textBlocksRef.on('child_added', this.debouncedGetSnapshot);
    this.textBlocksChildChanged = textBlocksRef.on('child_changed', this.debouncedGetSnapshot);
    this.textBlocksChildRemoved = textBlocksRef.on('child_removed', this.debouncedGetSnapshot);
  }

  componentWillUnmount = () => {
    const {path, firebase} = this.props;
    const strokesRef = firebase.database()
        .ref(`/${path}/strokes`);

    strokesRef.off('child_added', this.strokeChildAdded);
    strokesRef.off('child_changed', this.strokeChildChanged);
    strokesRef.off('child_removed', this.strokeChildRemoved);

    const imagesRef = firebase.database()
        .ref(`/${path}/images`);

    imagesRef.off('child_added', this.imageChildAdded);
    imagesRef.off('child_changed', this.imageChildChanged);
    imagesRef.off('child_removed', this.imageChildRemoved);

    const textBlocksRef = firebase.database()
        .ref(`/${path}/textBlocks`);

    textBlocksRef.off('child_added', this.textBlocksChildAdded);
    textBlocksRef.off('child_changed', this.textBlocksChildChanged);
    textBlocksRef.off('child_removed', this.textBlocksChildRemoved);
  }

  getSnapshot = () => {
    const {path, firebase} = this.props;
    const documentRef = firebase.database()
        .ref(`/${path}`);
    documentRef.once('value', this.onInitialSnapshot);
  }

  onInitialSnapshot = (snapshot) => {
    const data = snapshot.exportVal() || {
      images: {},
      strokes: {},
      textBlocks: {},
    };
    this.setState({
      data: data,
    }, this.recordStrokes);
  }

  getActivePage = () => {
    if (!this.state.data) {
      return null;
    }
    const {activePage} = this.state.data;
    if (!activePage) {
      return null;
    }
    return activePage;
  }

  onStrokeAdd = async (stroke, recordAction) => {
    trackEvent('board.canvas.strokeAdded');
    const {path, firebase} = this.props;

    const snapshot = await firebase.database().ref(`/${path}/strokes`).push();
    const savedStroke = {
      ...stroke,
      id: snapshot.key,
    };

    setTimeout(() => {
      this.updateStroke(savedStroke, `/${path}/strokes/${snapshot.key}`, recordAction);
      firebase.database().ref(`/${path}/strokes/${snapshot.key}`).set(savedStroke);
    }, 100);
    return savedStroke;
  }

  onImageAdd = async (image, recordAction) => {
    trackEvent('board.canvas.imageAdded');
    const {path, firebase} = this.props;

    const imagesRef = firebase.database()
        .ref(`/${path}/images`);

    const snapshot = await imagesRef.push();
    const savedImage = {
      ...image,
      id: snapshot.key,
      creatorId: getUserName() || 'web-app',
    };

    const action = {
      type: 'remove-image',
      path: `/${path}/images/${snapshot.key}`,
      data: image,
    };

    this.recordUndoRedo(recordAction, action);
    return firebase.database()
        .ref(`/${path}/images/${snapshot.key}`)
        .set(savedImage);
  }

  recordUndoRedo = (recordAction, action) => {
    switch (recordAction) {
      case 'redo':
        this.state.undo.pop();
        this.state.redo.push(action);
        break;
      case 'undo':
        this.state.redo.pop();
        this.state.undo.push(action);
        break;
      default:
        this.state.undo.push(action);
        this.state.redo = []; // eslint-disable-line react/no-direct-mutation-state
    }
  }
  onTextAdd = async (textBlock, recordAction) => {
    trackEvent('board.canvas.textAdded');
    const {path, firebase} = this.props;

    const textBlocksRef = firebase.database()
        .ref(`/${path}/textBlocks`);

    const snapshot = await textBlocksRef.push();
    const savedTextBlock = {
      ...textBlock,
      id: snapshot.key,
      creatorId: getUserName() || 'web-app',
    };

    const action = {
      type: 'remove-text',
      path: `/${path}/textBlocks/${snapshot.key}`,
      data: textBlock,
    };
    this.recordUndoRedo(recordAction, action);
    return firebase.database()
        .ref(`/${path}/textBlocks/${snapshot.key}`)
        .set(savedTextBlock);
  }

  deletePoint = (point) => {
    const {firebase, path} = this.props;
    const x = getPointBlock(point.x);
    const y = getPointBlock(point.y);
    if (!this.grid[x]) {
      return [];
    }
    if (!this.grid[x][y]) {
      return [];
    }
    const idsToDelete = this.grid[x][y];
    map(idsToDelete, async (id) => {
      if (this.deletedStrokes.indexOf(id) !== -1) {
        return;
      }
      trackEvent('board.canvas.strokeDeleted');
      this.state.undo.push({
        type: 'add-stroke',
        path: path,
        data: this.knownStrokes[id],
      });
      delete this.knownStrokes[id];
      this.state.redo = []; // eslint-disable-line react/no-direct-mutation-state
      this.deletedStrokes.push(id);
      await firebase.database().ref(`/${path}/strokes/${id}`).remove();
    });
    this.grid[x][y] = [];
    return idsToDelete;
  }

  updateStroke = (stroke, path, recordAction) => {
    const {firebase} = this.props;
    if (stroke.config.strokeType !== 'reference') {
      const action = {
        type: 'remove-stroke',
        path: path,
        data: stroke,
      };
      this.recordUndoRedo(recordAction, action);
    } else {
      setTimeout(async () => {
        await firebase.database().ref(path).remove();
        this.getSnapshot();
      }, 2000);
    };
    this.addStrokeToState(stroke);
  }

  addStrokeToState = (stroke) => {
    this.setState({
      data: {
        ...this.state.data,
        strokes: {
          ...this.state.data.strokes,
          [stroke.id]: stroke,
        },
      },
    }, this.recordStrokes);
  }

  recordStrokes = () => {
    const {strokes} = this.state.data;
    forEach(values(strokes), (stroke) => {
      if (this.knownStrokes[stroke.id]) {
        return;
      }
      this.knownStrokes[stroke.id] = stroke;
      forEach(values(stroke.samples), (sample) => {
        const x = getPointBlock(sample.x);
        const y = getPointBlock(sample.y);
        if (!this.grid[x]) {
          this.grid[x] = {};
        }
        if (!this.grid[x][y]) {
          this.grid[x][y] = [];
        }
        if (this.grid[x][y].indexOf(stroke.id) === -1) {
          this.grid[x][y].push(stroke.id);
        };
      });
    });
  }

  onUndo = () => {
    trackEvent('board.canvas.undo');
    if (!this.state.undo.length) {
      return;
    }
    const {firebase} = this.props;
    const action = this.state.undo[this.state.undo.length - 1];
    switch (action.type) {
      case 'remove-stroke':
        this.state.redo.push({
          type: 'add-stroke',
          path: action.path,
          data: action.data,
        });
        this.state.undo.pop();
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      case 'add-stroke':
        this.onStrokeAdd({...action.data}, 'redo');
        break;
      case 'add-image':
        this.onImageAdd({...action.data}, 'redo');
        break;
      case 'remove-image':
        this.state.redo.push({
          type: 'add-image',
          path: action.path,
          data: action.data,
        });
        this.state.undo.pop();
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      case 'remove-text':
        this.state.redo.push({
          type: 'add-text',
          path: action.path,
          data: action.data,
        });
        this.state.undo.pop();
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      case 'add-text':
        this.onTextAdd({...action.data}, 'redo');
        break;
      default:
        return null;
    }
  }

  onRedo = () => {
    trackEvent('board.canvas.redo');
    const {firebase} = this.props;
    if (!this.state.redo.length) {
      return;
    }
    const action = this.state.redo[this.state.redo.length - 1];
    switch (action.type) {
      case 'add-stroke':
        this.onStrokeAdd({
          ...action.data,
          config: {
            ...action.data.config,
            isRedo: true,
          },
        }, 'undo');
        break;
      case 'remove-stroke':
        this.state.redo.pop();
        this.state.undo.push({
          type: 'add-stroke',
          path: action.path,
          data: action.data,
        });
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      case 'add-image':
        this.onImageAdd({...action.data}, 'undo');
        break;
      case 'remove-image':
        this.state.redo.pop();
        this.state.undo.push({
          type: 'add-image',
          path: action.path,
          data: action.data,
        });
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      case 'add-text':
        this.onTextAdd({...action.data}, 'undo');
        break;
      case 'remove-text':
        this.state.undo.push({
          type: 'add-text',
          path: action.path,
          data: action.data,
        });
        this.state.redo.pop();
        firebase.database().ref(action.path).remove().then(this.getSnapshot);
        break;
      default:
        return null;
    }
  }


  render = () => {
    const {showControls, firebase, canvasStyle={}, classes, isExpanded} = this.props;
    const {data, undo, redo, zoom, hideGrid} = this.state;
    if (!data) {
      return <NotFound message="board-not-found"/>;
    }
    const {strokes, images, textBlocks} = data;
    return (
      <Fragment>
        <Canvas
          strokes={strokes || {}}
          images={images || {}}
          texts={textBlocks || {}}
          firebase={firebase}
          onStrokeAdd={this.onStrokeAdd}
          onImageAdd={this.onImageAdd}
          onTextAdd={this.onTextAdd}
          deletePoint={this.deletePoint}
          showControls={showControls}
          style={canvasStyle}
          zoom={zoom}
          hideGrid={hideGrid}
          ref="canvas"
          isExpanded={isExpanded}
        />
        {
          showControls && (
            <Grid container spacing={2} className={classes.topRight}>
              <Grid item xs={6}>
                <IconButton
                  size="small"
                  aria-label="more"
                  onClick={this.onUndo}
                  disabled={!undo.length}
                >
                  <UndoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  size="small"
                  aria-label="more"
                  onClick={this.onRedo}
                  disabled={!redo.length}
                >
                  <RedoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  size="small"
                  aria-label="more"
                  onClick={this.zoomOut}
                  disabled={zoom === 0.5}
                >
                  <ZoomOutIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  size="small"
                  aria-label="more"
                  onClick={this.zoomIn}
                  disabled={zoom > 0.5}
                >
                  <ZoomInIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!hideGrid}
                      onChange={this.toggleGrid}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={translate('grid')}
                />
              </Grid>
            </Grid>
          )
        }
      </Fragment>
    );
  }
}

export default withStyles(styles)(IndividualBoard);
