import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import {translate} from 'translations';
import {getUserName, setUserName} from 'utils/storage';
import {trackEvent, setTrackingUserId} from 'utils/tracking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InputName({open, setOpen, onSuccess}) {
  const handleClose = () => {
    trackEvent('modal.name.cancel');
    setOpen(false);
  }
  const [name, setName] = useState(getUserName());
  const onChange = (event) => setName(event.target.value);

  const onClickEnter = () => {
    if (!name) {
      return;
    }
    trackEvent('modal.name.enter');
    setTrackingUserId(name);

    setUserName(name);
    !!onSuccess && onSuccess();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={!!setOpen ? handleClose : null}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {translate('whats-your-name')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {translate('whats-your-name-description')}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name-input"
                label={translate('name')}
                onChange={onChange}
                defaultValue={name}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          !!setOpen && (
            <Button onClick={handleClose} color="primary">
              {translate('cancel')}
            </Button>
          )
        }
        <Button disabled={!name} onClick={onClickEnter} color="primary" variant="outlined">
          {translate('enter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
