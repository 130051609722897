import {map, filter, values, keys, forEach} from 'lodash';
import {getUserName} from 'utils/storage';
import {
  fromStrokeColorToLineColor,
  fromLineColorToStrokeColor,
} from 'utils/color';
import {white, pink} from 'constants/colors';

const MAX_X = 4.0;
const MAX_Y = 4.0;

export const getPointBlock = (point) => parseInt(point * 5, 10);

export const scaleX = (value, width) => {
  const X_SCALING_FACTOR = width / MAX_X;
  return value * X_SCALING_FACTOR;
};

export const reverseScaleX = (value, width) => {
  const X_SCALING_FACTOR = width / MAX_X;
  return value / X_SCALING_FACTOR;
};

export const scaleY = (value, height) => {
  const Y_SCALING_FACTOR = height / MAX_Y;
  return value * Y_SCALING_FACTOR;
};

export const reverseScaleY = (value, height) => {
  const Y_SCALING_FACTOR = height / MAX_Y;
  return value / Y_SCALING_FACTOR;
};

let strokeCache = {};
export const fromStrokesToCanvasLines = (strokes, height, width) => {
  return filter(map(values(strokes), (stroke) => {
    if (!stroke) {
      return null;
    }
    if (!strokeCache[height]) {
      strokeCache[height] = {};
    }
    if (!strokeCache[height][width]) {
      strokeCache[height][width] = {};

    }
    if (strokeCache[height][width][stroke.id]) {
      return strokeCache[height][width][stroke.id];
    }
    if (!stroke.samples || !keys(stroke.samples).length) {
      return null;
    }

    const {config} = stroke;
    if (!config) {
      return null;
    }

    let ret = null;
    switch (config.strokeType) {
      case 'stroke':
      case 'shape':
        ret = {
          id: stroke.id,
          isDotted: false,
          brushColor: fromStrokeColorToLineColor(config.color),
          brushRadius: config.width / 4,
          points: map(values(stroke.samples), (value) => ({
            x: scaleX(value.x, width),
            y: scaleY(value.y, height),
          })),
        };
        break;
      case 'reference':
        ret = {
          id: stroke.id,
          isDotted: true,
          brushColor: `rgba(${pink.color},0.5)`,
          brushRadius: 2,
          points: map(values(stroke.samples), (value) => ({
            x: scaleX(value.x, width),
            y: scaleY(value.y, height),
          })),
        };
        break;
      case 'eraser':
        ret = {
          id: stroke.id,
          isDotted: false,
          brushColor: `rgba(${white.color},1)`,
          brushRadius: config.width / 4,
          points: map(values(stroke.samples), (value) => ({
            x: scaleX(value.x, width),
            y: scaleY(value.y, height),
          })),
        };
        break;
      default:
        ret = null;
    }
    strokeCache[height][width][stroke.id] = ret;
    return ret;
  }));
};

export const fromCanvasLineToStroke = (line, height, width) => {
  if (!line) {
    return null;
  }

  const samples = {};
  forEach(line.points, (point, index) => {
    samples[index] = {
      x: reverseScaleX(point.x, width),
      y: reverseScaleY(point.y, height),
      isCompleted: index === line.points.length - 1,
      timestamp: Date.now() / 100000000,
    };
  });

  return {
    creatorId: getUserName() || 'web-app',
    config: {
      color: line.isDotted ? fromLineColorToStrokeColor(`rgba(${white.color},1)`) : fromLineColorToStrokeColor(line.brushColor),
      isRedo: false,
      strokeType: line.isDotted ? 'reference' : 'stroke',
      width: line.isDotted ? 0 : line.brushRadius * 4,
    },
    samples: samples,
  };
};

export const fromDatabaseTextsToCanvasTexts = (texts, height, width) => {
  if (!texts) {
    return [];
  }
  return map(values(texts), text => ({
    id: text.id,
    frame: {
      x: scaleX(text.frame.x, width),
      y: scaleY(text.frame.y+0.08, height),
    },
    color: fromStrokeColorToLineColor(text.color),
    text: text.text,
    fontSize: `${scaleY(text.frame.h, height)}px`,
    fontFamily: 'AvenirNext',
  }));
}

export const fromCanvasTextToDatabaseText = (data, height, width) => {
  return {
    color: fromLineColorToStrokeColor(data.color),
    frame: {
      x: reverseScaleX(data.coordinates.x, width),
      y: reverseScaleY(data.coordinates.y, height),
      h: reverseScaleY(data.fontSize, height),
      w: 0.6*reverseScaleY(data.fontSize, height)*data.text.length,
    },
    text: data.text,
  };
}
