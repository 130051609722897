import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {getKnownBoards} from 'utils/storage';
import {translate} from 'translations';
import {createNewBoard} from 'utils/database';
import Logo from 'components/Logo';
import JoinBoard from 'components/Modals/JoinBoard';
import InputName from 'components/Modals/InputName';
import {trackEvent} from 'utils/tracking';

const styles = (theme) => ({
  button: {
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 10,
    color: `${theme.palette.primary.contrastText} !important`,
  },
});

class AppHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      knowBoards: getKnownBoards(),
      openJoinBoard: false,
      openInputName: false,
      selectedBoard: '',
    };
  }

  componentDidMount() {
    trackEvent('home.loaded');
  }

  setOpenJoinBoard = (openJoinBoard) => this.setState({openJoinBoard});

  setOpenInputName = (openInputName) => this.setState({openInputName});

  openCreateNewBoard = () => this.setState({
    openInputName: true,
    selectedBoard: '',
  });

  onSuccess = async () => {
    const {selectedBoard} = this.state;
    if (selectedBoard) {
      this.setOpenJoinBoard(false);
      this.goToBoard(selectedBoard);
    } else {
      const {firebase} = this.props;
      if (!firebase) {
        return;
      }
      const newBoard = await createNewBoard(firebase);
      this.goToBoard(newBoard);
    }
  }

  goToBoard = (selectedBoard) => {
    if (!selectedBoard) {
      return;
    }
    window.location.href = `/board?board=${selectedBoard}`;
  }

  onBoardSelect = (selectedBoard) => {
    this.setState({
      openJoinBoard: false,
      openInputName: true,
      selectedBoard,
    });
  }
  render = () => {
    const {classes} = this.props;
    const {knowBoards, openJoinBoard, openInputName} = this.state;
    return (
      <Grid container spacing={7} justify="center" alignItems="center">
        <Grid item xs={12} sm={4} md={4}>
          <Typography color="secondary" variant="subtitle1">
            <Logo />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Grid container spacing={7}>
            <Grid item xs={12}>
              <Button onClick={this.openCreateNewBoard} className={classes.button} variant="contained" color="primary" size="large" fullWidth>
                {translate('create-new-room')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={!knowBoards.length} onClick={() => this.setOpenJoinBoard(true)} className={classes.button} variant="contained" color="primary" size="large" fullWidth>
                {translate('join-existing-room')}
              </Button>
              <JoinBoard onBoardSelect={this.onBoardSelect} knowBoards={knowBoards} open={openJoinBoard} setOpen={this.setOpenJoinBoard} />
              <InputName onSuccess={this.onSuccess} open={openInputName} setOpen={this.setOpenInputName} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AppHome);
