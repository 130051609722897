import React, {useState, Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import {getUserName} from 'utils/storage';
import InputName from 'components/Modals/InputName';
import Board from 'components/Board';
import Room from 'components/Room';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  fullHeight: {
    height: '100%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
  fullHeightExpandedBoard: {
    height: '100%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    [theme.breakpoints.down('sm')]: {
      height: '30%',
    },
  },
  fullHeightExpandedRoom: {
    height: '100%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    [theme.breakpoints.down('sm')]: {
      height: '70%',
    },
  },
}));

const BoardView = ({match, firebase}) => {
  const classes = useStyles();
  const [name, setName] = useState(getUserName());
  const [isExpanded, setIsExpanded] = useState(false);

  const onSuccess = () => setName(getUserName());
  const urlParams = new URLSearchParams(window.location.search);
  const board = urlParams.get('board') || '';

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
    window.onresize && window.onresize();
  }

  let content = null;
  if (name) {
    content = (
      <Grid container direction="row-reverse" spacing={0} className={classes.root}>
        <Grid item xs={12} sm={12} md={isExpanded ? 3 : 9} className={isExpanded ? classes.fullHeightExpandedBoard : classes.fullHeight}>
          <Board
            firebase={firebase}
            board={board.toLowerCase()}
            showControls
            name={name}
            isExpanded={isExpanded}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={isExpanded ? 9 : 3} className={isExpanded ? classes.fullHeightExpandedRoom : classes.fullHeight}>
          <Room
            firebase={firebase}
            board={board.toLowerCase()}
            name={name}
            isExpanded={isExpanded}
            toggleIsExpanded={toggleIsExpanded}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Fragment>
      {content}
      <InputName
        open={!name}
        onSuccess={onSuccess}
      />
    </Fragment>
  );
};

export default BoardView;
