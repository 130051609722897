import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import logo from 'images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    filter: 'invert(1)',
  },
}));

export default function Logo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.image} />
    </div>
  );
}
