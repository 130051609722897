import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="static">
        <Toolbar>
          <div className={classes.title}>
            <Button
              color="primary"
              href="mailto:inbound@trysprink.com"
            >
              Contact
            </Button>
            <Button
              color="primary"
              href="/terms"
            >
              Terms
            </Button>
            <Button
              color="primary"
              href="/privacy"
            >
              Privacy
            </Button>
            <IconButton href="https://twitter.com/trysprink" aria-label="Twitter" color="primary">
              <TwitterIcon />
            </IconButton>
            <IconButton href="https://www.facebook.com/trysprink" aria-label="facebook" color="primary">
              <FacebookIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
