import React, {Fragment, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {translate} from 'translations';
import {trackEvent} from 'utils/tracking';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Share = ({board}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const textInput = React.createRef();

  const handleClickOpen = () => {
    trackEvent('board.share.open');
    setOpen(true);
  };

  const handleClose = () => {
    trackEvent('board.share.close');
    setOpen(false);
  };

  const onClickCopy = () => {
    trackEvent('board.share.copy');
    textInput.current.children[0].select();
    document.execCommand('copy');
  };

  return (
    <Fragment>
      <Button variant="contained" color="primary" fullWidth size="large" onClick={handleClickOpen}>
        <ShareIcon />
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="share-dialog"
        aria-describedby="share-dialog"
      >
        <DialogTitle id="share-dialog-title">
          {translate('share-board')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="share-dialog-description">
            <Paper className={classes.root}>
              <InputBase
                value={window.location.href}
                className={classes.input}
                inputProps={{'aria-label': 'board-url'}}
                ref={textInput}
              />
              <Button
                type="submit"
                className={classes.iconButton}
                aria-label="copy"
                startIcon={<FileCopyIcon />}
                onClick={onClickCopy}
              >
                {translate('copy')}
              </Button>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {translate('hide')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Share;
