import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import CanvasDraw from 'components/CanvasDraw';
import {
  fromStrokesToCanvasLines,
  fromCanvasLineToStroke,
  fromDatabaseTextsToCanvasTexts,
  fromCanvasTextToDatabaseText,
  scaleX,
  scaleY,
  reverseScaleX,
  reverseScaleY,
} from 'utils/canvas';
import Controls from './Controls';
import ImageLayover from './ImageLayover';
import TextLayover from './TextLayover';
import ArrowKeys from './ArrowKeys';
import {values, map, isEqual, keys, filter, debounce} from 'lodash';
import {pink, white} from 'constants/colors';

const styles = (theme) => ({
  rootRoot: {
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    position: 'relative',
    overflow: 'hidden',
    margin: 20,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 20px)',
      width: 'calc(100% - 20px)',
      margin: 10,
    },
  },
  root: {
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    right: '-50%',
    bottom: '-50%',
  },
  bottomRight: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    zIndex: 100,
  },
  bottomLeft: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    zIndex: 100,
  },
});

class Canvas extends Component {
  state = {
    color: '0,0,0',
    alpha: 1,
    radius: 1,
    isReference: false,
    isShape: false,
    isEraser: false,
    isText: false,
    top: 0,
    left: 0,
  }

  knownStrokes = {};

  componentDidMount = () => {
    setTimeout(this.loadSaveData, 100);
    const debounced = debounce(this.loadSaveData, 500)
    window.onresize = () => debounced(true);
  }

  shouldComponentUpdate = (newProps, newState) => {
    if (
      !keys(newProps.texts).length
      && !keys(newProps.images).length
      && !keys(this.getFilteredStrokes(newProps.strokes)).length
    ) {
      this.clear(true);
      this.canvas.drawGrid(this.props.hideGrid);
    }
    if (
      !isEqual(keys(newProps.images), keys(this.props.images))
      || !isEqual(
        keys(this.knownStrokes),
        keys(this.getFilteredStrokes(newProps.strokes)),
      )
      || !isEqual(
        keys(newProps.texts),
        keys(this.props.text),
      )
      || newProps.hideGrid !== this.props.hideGrid
    ) {
      setTimeout(this.loadSaveData, 100);
    }
    return newProps.isExpanded !== this.props.isExpanded
      || newProps.zoom !== this.props.zoom
      || !isEqual(newState, this.state);
  }

  onErasePoint = (point) => this.props.deletePoint({
    x: reverseScaleX(point.x, this.canvas.canvasContainer.offsetWidth),
    y: reverseScaleY(point.y, this.canvas.canvasContainer.offsetHeight),
  });

  clear = (clearImages) => {
    this.knownStrokes = {};
    if (this.canvas) {
      this.canvas.clear(clearImages);
    }
  }

  loadSaveData = async (clearImages = false) => {
    if (this.canvas) {
      const images = await this.getImages();
      const texts = fromDatabaseTextsToCanvasTexts(
        this.props.texts,
        this.canvas.canvasContainer.offsetHeight,
        this.canvas.canvasContainer.offsetWidth,
      );
      if (this.images && this.images.length > images.length) {
        clearImages = true;
      }
      if (clearImages) {
        this.canvas.clear(clearImages);
      } else if (this.texts && this.texts.length > texts.length) {
        this.canvas.clear();
      }
      this.canvas.loadSaveData(
          this.getStrokes(),
          true,
      );
      this.canvas.drawGrid(this.props.hideGrid);
      this.canvas.drawImages(images);
      this.images = images;
      this.canvas.drawTexts(texts);
      this.texts = texts;
      this.knownStrokes = {
        ...this.getFilteredStrokes(),
      };
    }
  }

  getFilteredStrokes = (strokes) => filter(
    strokes ? strokes : this.props.strokes,
    (stroke) => stroke.samples && keys(stroke.samples).length,
  );

  getStrokes = () => {
    if (!this.canvas) {
      return '';
    }

    return {
      width: '100%',
      height: '100%',
      lines: fromStrokesToCanvasLines(
          this.getFilteredStrokes(),
          this.canvas.canvasContainer.offsetHeight,
          this.canvas.canvasContainer.offsetWidth,
      ),
    };
  }

  getTexts = () => {
    if (!this.props.texts) {
      return [];
    }
    return
  }

  getImages = () => {
    if (!this.canvas) {
      return new Promise((resolve) => resolve([]));
    }
    const {images, firebase} = this.props;
    const bucketRef = firebase.storage();
    return Promise.all(map(values(images), async (image) => {
      const {path, frame} = image;
      const src = await bucketRef.ref(path).getDownloadURL();
      return {
        src: src,
        frame: {
          h: scaleY(frame.h, this.canvas.canvasContainer.offsetHeight),
          w: scaleX(frame.w, this.canvas.canvasContainer.offsetWidth),
          x: scaleX(frame.x, this.canvas.canvasContainer.offsetWidth),
          y: scaleY(frame.y, this.canvas.canvasContainer.offsetHeight),
        },
      };
    }));
  }

  setColor = (color) => this.setState({color})

  setAlpha = (alpha) => this.setState({alpha})

  setRadius = (radius) => this.setState({radius})

  setIsReference = (isReference) => this.setState({isReference})

  setIsShape = (isShape) => this.setState({isShape})

  setIsEraser = (isEraser) => this.setState({isEraser});

  setIsText = (isText) => this.setState({isText});

  setPosition = (position) => this.setState(position);

  getZoom = () => this.props.showControls ? this.props.zoom : 0.5;

  onLineAdd = async (line) => {
    if (!this.canvas) {
      return;
    }
    if (line.brushColor && line.brushColor.indexOf(',0)') !== -1) {
      return;
    }

    const {onStrokeAdd} = this.props;
    const savedStroke = await onStrokeAdd(
        fromCanvasLineToStroke(
            line,
            this.canvas.canvasContainer.offsetHeight,
            this.canvas.canvasContainer.offsetWidth,
        ),
    );
    // force shapes to rerender
    if (!this.state.isShape) {
      this.knownStrokes[savedStroke.id] = savedStroke;
    }
    return savedStroke.id;
  }

  getInitialCoordinates = () => {
    if (!this.canvas || !this.canvas.canvasContainer) {
      return {
        x: 0,
        y: 0,
      };
    }
    const top = this.getZoom() === 0.5 ? 0 : this.state.top/100;
    const left = this.getZoom() === 0.5 ? 0 : this.state.left/100;
    return {
      x: parseInt((0.4-left) * this.canvas.canvasContainer.offsetWidth, 10),
      y: parseInt((0.4-top) * this.canvas.canvasContainer.offsetHeight, 10),
    };
  }

  onImageUpload = async (image) => {
    const {firebase} = this.props;
    const src = await firebase.storage().ref(image).getDownloadURL();
    this.setState({
      src: src,
      image: image,
    });
  }

  omImageCancel = () => this.setState({
    src: '',
    image: '',
  });

  onImageSave = async (frame) => {
    if (!this.canvas || !this.canvas.canvasContainer) {
      return;
    }
    const image = {
      path: this.state.image,
      frame: {
        x: reverseScaleX(frame.x, this.canvas.canvasContainer.offsetWidth),
        y: reverseScaleY(frame.y, this.canvas.canvasContainer.offsetHeight),
        w: reverseScaleX(frame.w, this.canvas.canvasContainer.offsetWidth),
        h: reverseScaleY(frame.h, this.canvas.canvasContainer.offsetHeight),
      },
    };
    const {onImageAdd} = this.props;
    if (!onImageAdd) {
      return;
    }
    await onImageAdd(image);
    this.setState({
      image: '',
      src: '',
    });
  }

  onTextSave = async (data) => {
    if (!this.canvas || !this.canvas.canvasContainer) {
      return;
    }
    const {onTextAdd} = this.props;
    if (!onTextAdd) {
      return;
    }
    const textBlock = fromCanvasTextToDatabaseText(
      data,
      this.canvas.canvasContainer.offsetHeight,
      this.canvas.canvasContainer.offsetWidth,
    );
    await onTextAdd(textBlock);
    this.setIsText(false);
  }

  getColor = () => {
    const {isReference, isEraser, color, alpha} = this.state;
    if (isReference) {
      return `rgba(${pink.color},0.5)`;
    }
    if (isEraser) {
      return `rgba(${white.color},${alpha})`;
    }
    return `rgba(${color},${alpha})`;
  }

  render = () => {
    const {classes, showControls, style={}, firebase, hideGrid, isExpanded} = this.props;
    const {radius, src, isReference, isShape, isText, color, top, left} = this.state;
    const zoom = this.getZoom();

    const canvasProps = {
      ref: (canvasDraw) => {
        this.canvas = canvasDraw;
        window.canvas = canvasDraw;
      },
      canvasWidth: '100%',
      canvasHeight: '100%',
      brushColor: this.getColor(),
      brushRadius: radius,
      onLineAdd: this.onLineAdd,
      onErasePoint: this.onErasePoint,
      isDotted: isReference,
      isShape: isShape,
      zoom: zoom,
      hideGrid: hideGrid,
      style: {
        top: zoom === 0.5 ? 0 : `${top}%`,
        left: zoom === 0.5 ? 0 : `${left}%`,
        position: 'relative',
        transform: `scale(${zoom})`,
        transition: 'all 0.5s ease 0s',
      },
      disabled: !showControls,
      lazyRadius: 0,
      immediateLoading: true,
    };
    return (
      <div className={classes.rootRoot} style={style}>
        <div className={classes.root}>
          <CanvasDraw {...canvasProps} />
        </div>
        {
          !isExpanded && !!showControls && (
            <div className={classes.bottomRight}>
              <Controls
                setColor={this.setColor}
                setAlpha={this.setAlpha}
                setRadius={this.setRadius}
                setIsReference={this.setIsReference}
                setIsShape={this.setIsShape}
                setIsEraser={this.setIsEraser}
                setIsText={this.setIsText}
                firebase={firebase}
                onImageUpload={this.onImageUpload}
              />
            </div>
          )
        }
        {
          !!showControls && zoom === 1 && (
            <div className={classes.bottomLeft}>
              <ArrowKeys setPosition={this.setPosition} top={top} left={left} />
            </div>
          )
        }
        {
          !!showControls && !!src && (
            <ImageLayover
              src={src}
              onCancel={this.omImageCancel}
              onSave={this.onImageSave}
              coordinates={this.getInitialCoordinates()}
              zoom={zoom}
              top={zoom === 0.5 ? 0 : top}
              left={zoom === 0.5 ? 0 : left}
            />
          )
        }
        {
          !!isText && (
            <TextLayover
              coordinates={this.getInitialCoordinates()}
              onCancel={() => this.setIsText(false)}
              onSave={this.onTextSave}
              zoom={zoom}
              color={`rgba(${color},1)`}
              top={zoom === 0.5 ? 0 : top}
              left={zoom === 0.5 ? 0 : left}
            />
          )
        }
      </div>
    );
  }
}

export default withStyles(styles)(Canvas);
