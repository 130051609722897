import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Layover from './Layover';

const styles = (theme) => ({
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'grab',
  },
});

class ImageLayover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 20,
    };
  }

  onSliderChange = (event, width) => this.setState({width})

  onSave = (coordinates) => {
    if (!this.refs.image) {
      return;
    }
    const {x, y} = coordinates;
    this.props.onSave({
      h: this.refs.image.height,
      w: this.refs.image.width,
      x: x,
      y: y,
    });
  }

  render = () => {
    const {classes, src, onCancel, zoom, coordinates, top, left} = this.props;
    const {width, } = this.state;
    return (
      <Layover
        onCancel={onCancel}
        onSave={this.onSave}
        zoom={zoom}
        sliderValue={width}
        coordinates={coordinates}
        onSliderChange={this.onSliderChange}
        top={top}
        left={left}
      >
        <img
          ref="image"
          alt="draggable"
          draggable="false"
          className={`handle ${classes.image}`}
          style={{width: `${width}%`}}
          src={src}
        />
      </Layover>
    );
  }
}

export default withStyles(styles)(ImageLayover);
