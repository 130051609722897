import {sortBy} from 'lodash';
import sketchy from 'utils/sketchy';

const NUM_INTERPOLATION_POINTS = 40;

export const getDistance = (point1, point2) => {
  const x = point1.x - point2.x;
  const y = point1.y - point2.y;
  return Math.sqrt(x*x + y*y);
};

export const sort = (values, by) => sortBy(values, [by]);

export const getMinX = (points) => {
  if (!points.length) {
    return 0;
  }
  return sortBy(points, ['x'])[0].x;
};

export const getMaxX = (points) => {
  if (!points.length) {
    return 0;
  }
  return sortBy(points, ['x'])[points.length - 1].x;
};

export const getMinY = (points) => {
  if (!points.length) {
    return 0;
  }
  return sortBy(points, ['y'])[0].y;
};

export const getMaxY = (points) => {
  if (!points.length) {
    return 0;
  }
  return sortBy(points, ['y'])[points.length - 1].y;
};

export const interpolate = (point1, point2, points) => {
  const ret = [];
  const stepX = (point2.x - point1.x) / points;
  const stepY = (point2.y - point1.y) / points;
  for (let i=0; i<=points; i++) {
    ret.push({
      x: point1.x + i*stepX,
      y: point1.y + i*stepY,
    });
  }
  return ret;
};

export const getRectanglePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: minX, y: minY}, {x: maxX, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: minY}, {x: maxX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: maxY}, {x: minX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: maxY}, {x: minX, y: minY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getUpTrianglePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: (minX + maxX)/2, y: minY}, {x: maxX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: maxY}, {x: minX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: maxY}, {x: (minX + maxX)/2, y: minY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getRightTrianglePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: maxX, y: (minY+maxY)/2}, {x: minX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: maxY}, {x: minX, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: minY}, {x: maxX, y: (minY+maxY)/2}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getDownTrianglePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: (minX + maxX)/2, y: maxY}, {x: minX, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: minY}, {x: maxX, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: minY}, {x: (minX + maxX)/2, y: maxY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getLeftTrianglePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: minX, y: (minY+maxY)/2}, {x: maxX, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: minY}, {x: maxX, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: maxY}, {x: minX, y: (minY+maxY)/2}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getUpArrowPoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: minX, y: maxY}, {x: (minX + maxX)/2, y: minY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: (minX + maxX)/2, y: minY}, {x: maxX, y: maxY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getRightArrowPoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: minX, y: minY}, {x: maxX, y: (minY+maxY)/2}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: maxX, y: (minY+maxY)/2}, {x: minX, y: maxY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getDownArrowPoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: maxX, y: minY}, {x: (minX + maxX)/2, y: maxY}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: (minX + maxX)/2, y: maxY}, {x: minX, y: minY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getLeftArrowPoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: maxX, y: maxY}, {x: minX, y: (minY+maxY)/2}, NUM_INTERPOLATION_POINTS),
    ...interpolate({x: minX, y: (minY+maxY)/2}, {x: maxX, y: minY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getLinePoints = (minX, maxX, minY, maxY) => {
  return [
    ...interpolate({x: minX, y: minY}, {x: maxX, y: maxY}, NUM_INTERPOLATION_POINTS),
  ];
};

export const getCirclePoints = (minX, maxX, minY, maxY) => {
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const radius = ((maxX - minX) + (maxY - minY))/4;
  const ret = [];
  for (let i = -1; i < NUM_INTERPOLATION_POINTS; i++) {
    ret.push({
      x: (centerX + radius * Math.cos(2 * Math.PI * i / NUM_INTERPOLATION_POINTS)),
      y: (centerY + radius * Math.sin(2 * Math.PI * i / NUM_INTERPOLATION_POINTS)),
    });
  }
  return ret;
};

export const getHausdorffDistance = (points1, points2) => {
  return Math.max(
      sketchy.hausdorff(points1, points2, {x: 0, y: 0}),
      sketchy.hausdorff(points2, points1, {x: 0, y: 0}),
  );
};
export const detectShape = (points) => {
  const sortedX = sort(points, 'x');
  const sortedY = sort(points, 'y');

  const minX = sortedX[0].x;
  const maxX = sortedX[sortedX.length - 1].x;
  const minY = sortedY[0].y;
  const maxY = sortedY[sortedX.length - 1].y;

  const possibleShapes = [
    getRectanglePoints(minX, maxX, minY, maxY),
    getUpTrianglePoints(minX, maxX, minY, maxY),
    getRightTrianglePoints(minX, maxX, minY, maxY),
    getDownTrianglePoints(minX, maxX, minY, maxY),
    getLeftTrianglePoints(minX, maxX, minY, maxY),
    getUpArrowPoints(minX, maxX, minY, maxY),
    getRightArrowPoints(minX, maxX, minY, maxY),
    getDownArrowPoints(minX, maxX, minY, maxY),
    getLeftArrowPoints(minX, maxX, minY, maxY),
    getLinePoints(minX, maxX, minY, maxY),
    getCirclePoints(minX, maxX, minY, maxY),
  ];

  let minDistancePoints = possibleShapes[0];
  let minDistance = getHausdorffDistance(possibleShapes[0], points);
  for (let i=1; i<possibleShapes.length; i++) {
    const distance = getHausdorffDistance(possibleShapes[i], points);
    if (distance < minDistance) {
      minDistance = distance;
      minDistancePoints = possibleShapes[i];
    }
  }
  return minDistancePoints;
};
