import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 10,
  }
}));

const Links = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Button className={classes.button} size="large" variant="contained" color="primary" href="https://apps.apple.com/us/app/sprink-app/id1468438908">
        App Store
      </Button>
      <Button className={classes.button}  href="/app" size="large" variant="contained" color="primary">Web App</Button>
      <Button className={classes.button}  href="https://slack.com/oauth/authorize?client_id=648360088758.702323399303&scope=commands,chat:write:bot,incoming-webhook" size="large" variant="contained" color="primary">Slack</Button>
    </Fragment>
  )
}

export default Links;
