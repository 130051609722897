import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import NotFound from 'components/NotFound';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'constants/theme';
import {getFirebase} from 'utils/firebase';

// views
import Board from 'views/Board';
import AppHome from 'views/AppHome';
import Privacy from 'views/Privacy';
import Terms from 'views/Terms';
import Home from 'views/Home';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.grey[900],
      height: '100vh',
      margin: 0,
      padding: 0,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
    },
  };
});

const firebase = getFirebase();

const App = () => {
  const classes = useStyles();
  const renderComponent = (Component, isApp) => (props) => {
    if (isApp) {
      return (
        <div className={classes.root}>
          <Component {...props} firebase={firebase} />
        </div>
      )
    }
    return (
      <Component {...props} firebase={firebase} />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/terms" render={renderComponent(Terms)} />
          <Route exact path="/privacy" render={renderComponent(Privacy)} />
          <Route exact path="/board" render={renderComponent(Board, true)} />
          <Route exact path="/app" render={renderComponent(AppHome, true)} />
          <Route exact path="/" render={renderComponent(Home)} />
          <Route path="*" render={renderComponent(NotFound)} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
