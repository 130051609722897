import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import {map, uniq} from 'lodash';
import {translate} from 'translations';
import {trackEvent} from 'utils/tracking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));


export default function JoinBoard({open, onBoardSelect, setOpen, knowBoards}) {
  const classes = useStyles();

  const [name, setName] = useState('');
  const onChange = (event) => setName(event.target.value);

  const handleClose = () => {
    trackEvent('modal.join.cancel');
    setOpen(false);
  }

  const handleSuccess = (board) => {
    trackEvent('modal.join.select');
    onBoardSelect(board);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {translate('join-existing-room')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={3}>
            {
              !!knowBoards && !!knowBoards.length && map(uniq(knowBoards), (board) => (
                <Grid item xs={12} key={board}>
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSuccess(board)}
                  >
                    {board}
                  </Button>
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder={translate('enter-room-name')}
                  inputProps={{ 'aria-label': translate('enter-room-name') }}
                  onChange={onChange}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <Button color="primary" disabled={!name}  onClick={() => handleSuccess(name)}>
                  {translate('join')}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
