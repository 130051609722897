const toBinary = (num) => {
  const n = parseInt(num, 10).toString(2);
  return '00000000'.substr(n.length) + n;
};

export const fromStrokeColorToLineColor = (color) => {
  const r = (color >>> 16) & 0xFF;
  const g = (color >>> 8) & 0xFF;

  // NB: need >>> 0 to convert.color from float to int in javascript.
  const b = (color >>> 0) & 0xFF;
  const a = ((color >>> 24) & 0xFF)/255.0;

  return `rgba(${r},${g},${b},${a})`;
};

export const fromLineColorToStrokeColor = (color) => {
  const split = color.replace('rgba(', '').replace(')', '').split(',');

  const alpha = parseInt(parseFloat(split[3], 10) * 255, 10);
  //
  const bin = `${toBinary(alpha)}${toBinary(split[0])}${toBinary(split[1])}${toBinary(split[2])}`; // eslint-disable-line
  return parseInt(bin, 2);
};
