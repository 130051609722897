import amplitude from 'amplitude-js';

const tracker = amplitude.getInstance();
tracker.init('af28111c78dfa870cf41a2246a09a93a', null, {batchEvents: true});

export const setTrackingUserId = (userId) => {
  tracker.setUserId(userId);
  tracker.regenerateDeviceId();
};

export const trackEvent = (name) => new Promise(resolve => {
  resolve(tracker.logEvent(name));
});
