import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {trackEvent} from 'utils/tracking';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-20px, 10px)',
    },
  },
  button: {
    padding: 0,
  }
});

class ArrowKeys extends Component {
  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKey, false);
  }

  componentWillUnmount () {
    document.removeEventListener("keydown", this.handleKey, false);
  }

  handleKey = (event) => {
    const {setPosition, top, left} = this.props;
    switch (event.keyCode) {
      case 37:
        trackEvent('board.canvas.left');
        return setPosition({left: Math.min(left + 5, 25)});
      case 38:
        trackEvent('board.canvas.up');
        return setPosition({top: Math.min(top + 5, 25)});
      case 39:
        trackEvent('board.canvas.right');
        return setPosition({left: Math.max(left - 5, -25)});
      case 40:
        trackEvent('board.canvas.down');
        return setPosition({top: Math.max(top - 5, -25)});
      default:
        return null;
    }
  }

  render() {
    const {classes, top, left} = this.props;
    return (
      <Grid container className={classes.root} spacing={0}>
        <Grid item textAlign="center" xs={12}>
          <IconButton disabled={top >= 25} className={classes.button} size="small" aria-label="up" onClick={() => this.handleKey({keyCode:38})}>
            <KeyboardArrowUpIcon />
          </IconButton>
        </Grid>
        <Grid item textAlign="center" xs={6}>
          <IconButton disabled={left >= 25} className={classes.button} size="small" aria-label="left" onClick={() => this.handleKey({keyCode:37})}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item textAlign="center" xs={6}>
          <IconButton disabled={left <= -25} className={classes.button} size="small" aria-label="right" onClick={() => this.handleKey({keyCode:39})}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
        <Grid item textAlign="center" xs={12}>
          <IconButton disabled={top <= -25} className={classes.button} size="small" aria-label="down" onClick={() => this.handleKey({keyCode:40})}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ArrowKeys);
