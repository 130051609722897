import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#D9DEE1',
    overflow: 'auto',
  },
  subheader: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: 50,
    textAlign: 'center',
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: 50,
  }
}));

const Layout = ({children, title, subtitle}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header title={title} subtitle={subtitle} />
      <div className={classes.subheader}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.content}>
        {children}
      </div>
      <Footer />
    </div>
  );
};
export default Layout;
