import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import Fab from '@material-ui/core/Fab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import Slider from '@material-ui/core/Slider';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    overflow: 'hidden',
    backgroundColor: 'rgba(255,255,255,0.85)',
  },
  draggableContainer: {
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    right: '-50%',
    bottom: '-50%',
    transition: 'all 0.5s ease 0s',
  },
  buttons: {
    textAlign: 'center',
  },
  cancel: {
    color: 'red',
    margin: 10,
    position: 'relative',
    zIndex: 1,
  },
  save: {
    color: 'green',
    margin: 10,
    position: 'relative',
    zIndex: 1,
  },
  slider: {
    width: '50%',
    margin: '10px auto',
    position: 'relative',
    zIndex: 1,
  },
});

class Layover extends Component {
  constructor(props) {
    super(props);
    const {coordinates} = props;
    this.state = {
      coordinates: coordinates,
    };
  }

  componentWillMount(){
    document.addEventListener("keydown", this.handleKey, false);
  }

  componentWillUnmount () {
    document.removeEventListener("keydown", this.handleKey, false);
  }

  handleKey = (event) => {
    switch (event.keyCode) {
      case 13:
        return this.onSave();
      case 27:
        return this.props.onCancel();
      default:
        return null;
    }
  }

  onSave = () => this.props.onSave(this.state.coordinates);

  render = () => {
    const {
      classes,
      onCancel,
      zoom,
      sliderValue,
      coordinates,
      onSliderChange,
      children,
      sliderMin,
      sliderMax,
      top,
      left,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Fab className={classes.cancel} onClick={onCancel}>
            <FontAwesomeIcon size="lg" icon={faTrashAlt} />
          </Fab>
          <Fab className={classes.save} onClick={this.onSave}>
            <FontAwesomeIcon size="lg" icon={faCheck} />
          </Fab>
        </div>
        <div className={classes.slider}>
          <Slider
            defaultValue={sliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            min={sliderMin || 5}
            max={sliderMax || 100}
            onChange={onSliderChange}
          />
        </div>
        <div
          className={classes.draggableContainer}
          style={{
            transform: `scale(${zoom})`,
            top: `${-50+(2*top)}%`,
            left: `${-50+(2*left)}%`,
            bottom: `${-50-(2*top)}%`,
            right: `${-50-(2*left)}%`,
          }}
        >
          <Draggable
            handle=".handle"
            defaultPosition={coordinates}
            position={null}
            scale={1}
            bounds="parent"
            onStop={(event, {x, y}) => this.setState({coordinates: {x, y}})}
            enableUserSelectHack={false}
          >
            {children}
          </Draggable>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Layover);
