import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import Switcher from 'components/Modals/Switcher';
import IndividualBoard from './IndividualBoard';
import Fab from '@material-ui/core/Fab';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {addKnownBoard} from 'utils/storage';
import {translate} from 'translations';
import {hashString} from 'utils/string';
import {trackEvent} from 'utils/tracking';

const styles = (theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
  isExpandedRoot: {
    position: 'relative',
    height: '50%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  topLeft: {
    position: 'absolute',
    top: 30,
    left: 30,
    zIndex: 20,
    [theme.breakpoints.down('sm')]: {
      top: 20,
      left: 20,
    },
  },
});

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      open: false,
    };
  }

  handleClickOpen = () => {
    trackEvent('board.canvas.switcherOpen');
    this.setState({open: true});
  }

  handleClose = () => this.setState({open: false})

  componentDidMount = () => {
    const {board, firebase} = this.props;
    addKnownBoard(board);
    firebase.database()
        .ref(`/documents/${board}`)
        .once('value', this.onInitialSnapshot);

    firebase.database()
        .ref(`/documents/${board}/activePage`)
        .on('child_changed', this.onActivePageChange);

    firebase.database()
        .ref(`/documents/${board}/pages`)
        .on('child_added', this.onPageAdded);

    this.heartbeatInterval = window.setInterval(this.heartbeat, 1000);
    trackEvent('board.loaded');
  }

  componentWillUnmount = () => {
    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval);
      delete this.heartbeatInterval;
    }
  }

  onInitialSnapshot = (snapshot) => {
    this.setState({
      loading: false,
      data: snapshot.exportVal(),
    });
  }

  heartbeat = () => {
    const {name, board, firebase} = this.props;
    firebase.database()
        .ref(`/documents/${board}/participants/${hashString(name)}`)
        .set({
          lastSeen: Date.now(),
          name: name,
        });
  }

  getActivePage = () => {
    if (!this.state.data) {
      return null;
    }
    const {activePage} = this.state.data;
    if (!activePage) {
      return null;
    }
    return activePage;
  }

  onActivePageChange = (snapshot) => {
    this.setState({
      data: {
        ...this.state.data,
        activePage: {
          ...this.state.data.activePage,
          [snapshot.key]: snapshot.exportVal(),
        },
      },
    });
  }

  onPageAdded = (snapshot) => {
    this.setState({
      data: {
        ...this.state.data,
        pages: {
          ...this.state.data.pages,
          [snapshot.key]: snapshot.exportVal(),
        },
      },
    });
  }

  changeActivePage = (activePage) => {
    const {board, firebase} = this.props;

    const activePageRef = firebase.database()
        .ref(`/documents/${board}/activePage`);

    activePageRef.set(activePage);
    this.setState({
      data: {
        ...this.state.data,
        activePage: activePage,
      },
    });
  }

  addNewPage = async () => {
    trackEvent('board.canvas.addNewPage');
    const {board, firebase} = this.props;
    const snapshot = await firebase.database().ref('/pages').push();
    const activePage = {
      pageId: snapshot.key,
      pagesPath: 'pages',
    };
    await firebase.database().ref(`/documents/${board}/pages`).push(activePage);
    this.changeActivePage(activePage);
  }

  clearBoard = () => {
    trackEvent('board.canvas.clearBoard');
    if (!window.confirm(translate('confirm-delete'))) {
      return;
    }
    const {firebase} = this.props;
    const activePage = this.getActivePage();
    if (!activePage) {
      return null;
    }
    const {pagesPath, pageId} = activePage;
    const path = `${pagesPath.toLowerCase()}/${pageId.toLowerCase()}`;

    firebase.database()
        .ref(`/${path}/strokes`)
        .remove();
    firebase.database()
        .ref(`/${path}/images`)
        .remove();
    firebase.database()
        .ref(`/${path}/textBlocks`)
        .remove();

    if (this.refs.board) {
      this.refs.board.setState({
        data: {
          images: {},
          strokes: {},
        },
        undo: [],
        redo: [],
      });
    }
  }

  render = () => {
    const {classes, showControls, firebase, canvasStyle={}, isExpanded} = this.props;
    const {data, loading} = this.state;
    if (loading) {
      return <Loading />;
    }

    const activePage = this.getActivePage();
    if (!data || !activePage) {
      return <NotFound message="board-not-found"/>;
    }

    const {pagesPath, pageId} = activePage;
    const path = `${pagesPath.toLowerCase()}/${pageId.toLowerCase()}`;
    return (
      <div className={isExpanded ? classes.isExpandedRoot : classes.root}>
        <IndividualBoard
          key={path}
          showControls={showControls}
          canvasStyle={canvasStyle}
          firebase={firebase}
          path={path}
          ref="board"
          isExpanded={isExpanded}
        />
        {
          showControls && (
            <div>
              <Switcher
                firebase={firebase}
                open={this.state.open}
                handleClose={this.handleClose}
                handleClickOpen={this.handleClickOpen}
                activePage={data.activePage}
                pages={data.pages}
                addNewPage={this.addNewPage}
                changeActivePage={this.changeActivePage}
                clearBoard={this.clearBoard}
                isExpanded={isExpanded}
              />

              <Fab
                size="small"
                aria-label="more"
                className={classes.topLeft}
                onClick={this.handleClickOpen}
              >
                <MoreHorizIcon />
              </Fab>
            </div>
          )
        }
      </div>
    );
  }
}

export default withStyles(styles)(Board);
