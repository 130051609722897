import React from 'react';
import Layout from 'components/Layout';
import Typography from '@material-ui/core/Typography';

const TermsView = () => {
  return (
    <Layout title="TERMS OF SERVICE" subtitle="Last Update: June 5, 2019">
      <Typography>

        <p>
          Sprink is owned and operated by Sprink, Inc. and consists of the website and services available through the website (collectively the “<b>Services</b>”). The Services give users one-click access to all their accounts on third-party cloud services, and give administrators a single place to manage them. The following terms and conditions and the <a href="privacy.html">Sprink Privacy Policy</a> (collectively, the “<b>Terms</b>”) govern your access to and use of the Sprink (“<b>we</b>” or “<b>our</b>”) website and services (the “<b>Services</b>”). <b>By using the Services you agree to be bound and abide by these terms and conditions</b>. You may use the Service only if you have the power to form a legal contract with Sprink. If you are using the Services on behalf of an organization, you are agreeing to these Terms for that organization and promising that you have the authority to bind that organization to these terms. In that case, “you” and “your” will refer to that organization.
        </p>

        <h2>MODIFICATIONS</h2>
        <p>
          We reserve the right to update the Service and these Terms of Service from time to time, at our discretion and without notice. If a revision, in our sole discretion, is material we will notify you using the email address you have associated with your account. <b>Your continued use of the Services following the publishing of updated Terms means that you accept and agree to the changes</b>.
        </p>

        <h2>YOUR DATA</h2>
        <p>
          In order to use our Services, you may choose to provide us access to electronic files and items (“<b>Your Data</b>”) you have stored in third-party applications, such as Google Docs or Dropbox. When you choose to access a third-party application using Sprink, we access Your Data so that we can combine it and present it to you. We may also access information associated with Your Data that you might not usually see, such as time stamps and labels. We don’t claim any ownership of Your Data. Whatever ownership rights you have in Your Data do not change when you use the Service, except that you give us the permission necessary to perform the Service for you.
        </p>

        <h2>PRIVACY AND DATA SECURITY</h2>
        <p>
          The <b><a href="privacy.html">Sprink Privacy Policy</a></b> is incorporated into these Terms by reference. Please read the Privacy Policy carefully for information relating to our collection, use, and disclosure of Your Data and your personal information in connection with the Service. Aside from the rare exceptions we identify in our Privacy Policy, we won’t share Your Data with others for any purpose.
        </p>

        <h2>ACCOUNT SECURITY AND INFORMATION</h2>
        <p>
          You are responsible for safeguarding the access credentials that you use to access the Service and you agree not to provide these credentials to any third party. You are responsible for any activity using your account, whether or not you authorized that activity. You should immediately notify Sprink of any unauthorized use of your account. If your contact information, or other information related to your account, changes, you must notify us promptly and keep your information current. We reserve the right to disable any user account, at any time in our sole discretion for any or no reason, including, if in our opinion you have failed to comply with any provision of these Terms of Service.
        </p>

        <h2>ELIGIBILITY</h2>
        <p>
          The Service is not intended for use by you if you are under 13 years of age. <b>By agreeing to these Terms, you are represent that you are over 13</b>.
        </p>

        <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          The Service is owned and operated by Sprink. The Service and its entire content, features and functionality are owned by Sprink and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. You agree to not copy, modify, create derivative works of, publicly display, publicly perform, republish, any of our copyrighted material. While we appreciate user feedback, please understand that we may use any feedback, comments, or suggestions you provide without any obligation to you.
        </p>

        <h2>LAW AND COPYRIGHT INFRINGEMENT</h2>
        <p>
          In the unlikely event we receive a disclosure request from an authorized party, we reserve the right to disclose user identities when required to do so by the law, including in response to a law enforcement request supported by a valid court order. You waive and hold harmless the Company from any claims resulting from any action taken by the Company during or as a result of its investigations and from any actions taken as a consequence of investigations by either the Company or law enforcement authorities.
        </p>
        <p>
          If you believe that any User Content violates your copyright, please follow the procedure set forth by article 512(c) of the DMCA and provide us a written takedown notice including the following information:
        </p>
        <ol>
          <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
          <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.</li>
          <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.</li>
          <li>Information reasonably sufficient for us to contact you, such as email, address, telephone number.</li>
          <li>A statement that you a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
          <li>A statement that the information in the notification is accurate, and under penalty of perjury, that you is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
        </ol>
        <p>
          The notice should be addressed to <a href="mailto:support@sprink.com">support@sprink.com</a>.
        </p>

        <h2>DISCLAIMER OF WARRANTIES, LIMITATIONS OF LIABILITY AND INDEMNIFICATION.</h2>
        <p>
          <b>Your use of Sprink is at your sole risk. The service is provided “as is” and “as available”</b>. We disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. We are not liable for damages, direct or consequential, resulting from your use of the Service, and you agree to defend, indemnify and hold us harmless from any claims, losses, liability costs and expenses (including but not limited to attorney's fees) arising from your violation of any third-party's rights. You acknowledge that you have only a limited, non-exclusive, nontransferable license to use the Service. Because the Service is not error or bug free, you agree that you will use it carefully and avoid using it ways which might result in any loss of your or any third party's property or information.
        </p>

        <h2>GOVERNING LAW AND JURISDICTION</h2>
        <p>
          These Terms of Service and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the State of New York without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Service shall be instituted exclusively in the federal courts of the United States or the courts of the State of New York.
        </p>

        <h2>WAIVER AND SEVERABILITY</h2>
        <p>
          Our failure to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Sprink and govern your use of the service, superseding any prior agreements (including, but not limited to, any prior versions of the Terms of Service). If any provision of these Terms of Service is held by a court of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.
        </p>
      </Typography>
    </Layout>
  );
};
export default TermsView;
