import {
  faPaintBrush,
  faBrush,
  faShapes,
  faImage,
  faEraser,
  faHighlighter,
  faHandPointUp,
  faFont,
} from '@fortawesome/free-solid-svg-icons';

export default [
  [
    {
      id: 'reference',
      icon: faHandPointUp,
      radius: 2,
      alpha: 0.5,
    },
  ],
  [
    {
      id: 'paintBrush',
      icon: faPaintBrush,
      radius: 1,
      alpha: 1,
    },
    {
      id: 'brush',
      icon: faBrush,
      radius: 7.5,
      alpha: 1,
    },
    {
      id: 'highlighter',
      icon: faHighlighter,
      radius: 5,
      alpha: 0.5,
    },
    {
      id: 'shapes',
      icon: faShapes,
      radius: 2,
      alpha: 1,
    },
    {
      id: 'eraser',
      icon: faEraser,
      radius: 5,
      alpha: 1,
      options: ['stroke', 'free'],
    },
    {
      id: 'text',
      icon: faFont,
    },
    {
      id: 'image',
      icon: faImage,
    },
  ],
];
