import {get, forEach} from 'lodash';
import en from './en.json';

const locales = {
  en,
};

export const translate = (key, ...strings) => {
  // todo: find current locale from cookie
  let string = get(locales['en'], key) || key;
  forEach(strings, (s) => {
    string = string.replace('%s', s);
  });
  return string;
};
