import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppHome from 'components/AppHome';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const AppHomeView = ({firebase}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center" spacing={3}>
      <Grid item xs={10} sm={11} md={8} lg={6}>
        <AppHome firebase={firebase} />
      </Grid>
    </Grid>
  );
};
export default AppHomeView;
