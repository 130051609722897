import {get, set} from 'local-storage';
import {uniq} from 'lodash';

const MAX_BOARDS = 3;

export const getKnownBoards = () => uniq(get('knownBoards') || []);

export const addKnownBoard = (board) => {
  set('knownBoards', uniq([
    board,
    ...getKnownBoards(),
  ]).slice(0, MAX_BOARDS));
};

export const setUserName = (userName) => set('userName', userName);

export const getUserName = () => get('userName') || '';
