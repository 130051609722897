import React from 'react';
import Layout from 'components/Layout';
import Typography from '@material-ui/core/Typography';

const PrivacyView = () => {
  return (
    <Layout title="PRIVACY POLICY" subtitle="Last Update: July 11, 2013">
      <Typography>

        <p>
          This privacy policy (“<strong>Policy</strong>”) explains how personally identifiable information is collected, used and disclosed by Sprink, Inc. (“<strong>Sprink</strong>”, “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”) with respect to your access and use of the trysprink.com website located at <a href="https://www.trysprink.com">https://www.trysprink.com</a> (the “<strong>Site</strong>”) and the Sprink application (collectively, the “<strong>Service</strong>”), so you can make an informed decision about using the Service.
        </p>

        <p>
          We reserve the right to change the provisions of this Policy at any time. We will alert you that changes have been made by indicating on this Policy the date it was last updated. We encourage you to review this Policy from time-to-time to make sure that you understand how any personally identifiable information you provide to us or collected about you will be used.
        </p>

        <h2>
          WHAT IS PERSONALLY IDENTIFIABLE INFORMATION?
        </h2>
        <p>
          As used in this Policy, the term “personally identifiable information” means information that specifically identifies an individual (such as a name, user name or e-mail address), or information about that individual that is directly linked to information that specifically identifies an individual. Personally identifiable information does not include “aggregate” information, which is data we collect about the use of the Service or about a group or category of services or users, from which individual identities or other personally identifiable information has been removed. This Policy in no way restricts or limits our collection and use of aggregate information.
        </p>
        <h2>
          WHAT INFORMATION DO WE COLLECT?
        </h2>
        <p>
          Active Collection: We collect information from you in various ways when you use the Service. For example, we collect information: (i) that you voluntarily provide to us, such as when you send us an email or create a Sprink account; and (ii) to which you provide us access. Such information may include personally identifiable information, such as your name, email address, other account information and the nature and pattern of the access and use of the information, data and content accessed and used in connection with the use of the Service.
        </p>
        <p>
          Passive Collection: When you use the Service, some information is also automatically collected, such as your Internet Protocol (IP) address, your operating system, the browser type, the address of a referring website, and your activity on the Service. We may also automatically collect certain logistical information in server logs, including information about how you use various features of the Service and information about the number, frequency and length of each session.
        </p>
        <p>
          We may also automatically collect certain information through the use of “cookies” when you use the Service. Cookies are small data files stored on your hard drive at the request of a website. Among other things, cookies help us to improve the Service and your experience. If we link cookies to any personally identifiable information, we will treat this information as personally identifiable information. If you wish to block, erase, or be warned of cookies, please refer to your browser manufacturer to learn about these functions. However, if you choose to remove or reject cookies, this could affect certain features or services made available via the Service.
        </p>
        <p>
          The use of cookies by our partners, affiliates, tracking utility companies, Service Providers (as defined below) is not covered by this Policy.
        </p>
        <p>
          We may use Web beacons or similar technology on the Service. Web beacons are small, invisible graphic images that may be used on the Service or in emails relating to the Service to collect certain information and monitor user activity on the Service, such as to count visits, understand usage effectiveness and to tell if an email has been opened and acted upon.
        </p>
        <p>
          We use Google to analyze usage of our web site, and for advertising. We use Google's Display Advertising and Remarketing with Google Analytics. Third-party vendors, including Google, show our ads on sites across the Internet. We and third-party vendors, including Google, use first-party and third-party cookies to inform, optimize, and serve ads based on your past visits to our website. You can opt out of this advertising through Google's Ads Settings.
        </p>
        <p>
          Personally identifiable information will be stored on secured servers, and we may store and process personally identifiable information in the United States and other countries.
        </p>
        <h2>
          HOW DO WE USE THE PERSONALLY IDENTIFIABLE INFORMATION WE COLLECT?
        </h2>
        <p>
          We use personally identifiable information collected through the Service for the purposes described in this Policy or elsewhere on the Service. For example, we may use personally identifiable information we collect:
        </p>
        <p>
          to process and complete any transactions in connection with the Service;<br />
          to request feedback and to otherwise contact you about your use of the Service;<br />
          to respond to your emails, questions, comments, requests and complaints, and to provide customer service;<br />
          to monitor and analyze the Service and other usage and trends of the Services and the materials accessed via the use of the Service;<br />
          to personalize and improve the Service, and to increase the Service’s functionality and user friendliness;<br />
          to send you confirmations, updates, security alerts, and administrative messages, and otherwise facilitate your use of, and our administration and operation of, the Service;<br />
          to notify you about important changes to the Service; and<br />
          for any other purpose for which the information was collected.<br />
        </p>
        <h2>
          WHAT PERSONALLY IDENTIFIABLE INFORMATION DO WE SHARE WITH THIRD-PARTIES?
        </h2>
        <p>
          We will not knowingly share the personally identifiable information we collect from you through the Service with third-parties, except as described in this Policy. For example, we may share personally identifiable information as follows:
        </p>
        <p>
          with vendors, consultants, and other service providers (“Service Providers”) who are engaged by or working with us in connection with the operation of the Service and who need access to such information to carry out their work for us (such as a credit card processing company to bill you for the Service, and an email service provider to send out emails on our behalf);<br />
          when you give us your consent to do so, including if we notify you via the Service that the information you provide will be shared in a particular manner and you provide such information;<br />
          in an aggregated or anonymized form that does not directly identify you;<br />
          when we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or to respond to lawful requests or legal authorities including, but not limited to, responding to lawful subpoenas, warrants, or court orders;<br />
          when we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to protect the rights, property, or safety of Sprink, our users, our employees, copyright owners, third-parties or the public including, but not limited to, protecting Sprink or our users from fraudulent, abusive, inappropriate, or unlawful use of the Service;<br />
          to enforce or apply this Policy, the Terms, or our other policies or agreements; and<br />
          in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where personally identifiable information may be disclosed or transferred as one of the business assets of Sprink.<br />
        </p>
        <p>
          You acknowledge and agree that your personally identifiable information may be disclosed or transferred as one of the business assets of Sprink.
        </p>
        <p>
          We are not responsible for the actions of Service Providers or other third-parties, nor are we responsible for any additional information you provide directly to any other users or third-parties, and we encourage you to become familiar with third-party privacy practices before disclosing information directly to any such third-parties. Nothing herein restricts the sharing of aggregated or anonymized information, which may be shared with third-parties without your consent.
        </p>
        <h2>
          ACCESS TO PERSONALLY IDENTIFIABLE INFORMATION
        </h2>
        <p>
          If your personally identifiable information changes, or if you no longer desire the Service, you may correct, update or delete it by making a change to your Sprink account or by emailing our customer support at <a href="mailto:support@sprink.com">support@sprink.com</a>.
        </p>
        <p>
          We will retain your information for as long as your account is active or as needed to provide the Service. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
        </p>
        <h2>
          LINKS TO OTHER WEBSITES
        </h2>
        <p>
          The Service may contain links to other websites. Any personally identifiable information you provide on the linked pages is provided directly to that third-party and is subject to that third-party’s privacy policy. This Policy does not apply to such linked websites, and we are not responsible for the content or privacy and security practices and policies of these websites or any other websites that are linked to/from the Service. We encourage you to learn about their privacy and security practices and policies before providing them with personally identifiable information.
        </p>
        <h2>
          WHAT STEPS DO WE TAKE TO PROTECT YOUR INFORMATION ONLINE?
        </h2>
        <p>
          We take reasonable measures to protect your personally identifiable information in an effort to prevent loss, misuse, and unauthorized access, disclosure, alteration, and destruction. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can guarantee against any interception or any other type of misuse. To assist with the protection of personally identifiable information, you must keep your password confidential and not disclose it to any other person. You are responsible for all uses of the Service by any person using your password. Please advise us immediately if you believe your password has been misused. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our Service, you can contact us at <a href="mailto:support@sprink.com">support@sprink.com</a>.
        </p>
        <h2>
          WHAT CHOICES DO YOU HAVE REGARDING THE USE OF YOUR INFORMATION?
        </h2>
        <p>
          You may “opt out” of receiving marketing or promotional emails from us by following the instructions in those emails. You may also opt out of such marketing emails by following the instructions within such emails or emailing us at <a href="mailto:support@sprink.com">support@sprink.com</a>. If you opt out, we may still send you non-promotional emails, such as emails about your account or our ongoing business relations.
        </p>
        <h2>
          QUESTIONS?
        </h2>
        <p>
          If you have any questions about this Policy, please contact us at <a href="mailto:support@sprink.com">support@sprink.com</a>.
        </p>
      </Typography>
    </Layout>
  );
};
export default PrivacyView;
