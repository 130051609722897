import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {translate} from 'translations';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: '20vh',
    textAlign: 'center',
    opacity: 0.7,
  },
  icon: {
    fontSize: 100,
    margin: 50,
  },
  heading: {
    color: theme.palette.primary.contrastText,
  },
  details: {
    marginTop: 20,
    color: theme.palette.primary.contrastText,
  },
}));

export default function NotFound({message}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReportProblemIcon className={classes.icon} color="error" />
      <Typography variant="h3" className={classes.heading}>
        {translate('not-found')}
      </Typography>
      <Typography component="p" className={classes.details}>
        {translate(message || 'page-not-found')}
      </Typography>
    </div>
  );
}
