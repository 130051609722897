import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import buttonsArr from 'constants/buttons';
import colors from 'constants/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {map} from 'lodash';
import {translate} from 'translations';
import FileUploader from 'react-firebase-file-uploader';
import {trackEvent} from 'utils/tracking';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'right',
  },
  button: {
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      minHeight: 30,
      height: 30,
      width: 30,
      margin: 3,
    },
  },
  color: {
    display: 'inline-block',
    height: 28,
    width: 28,
    margin: 5,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  smallColor: {
    display: 'inline-block',
    height: 17,
    width: 17,
    margin: 4,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  selectedColor: {
    height: 32,
    width: 32,
    margin: 1,
  },
  smallSelectedColor: {
    height: 20,
    width: 20,
    margin: 1,
  },
}));

const Controls = ({
  setRadius,
  setColor,
  setAlpha,
  setIsEraser,
  setIsReference,
  setIsShape,
  setIsText,
  firebase,
  onImageUpload,
}) => {
  const classes = useStyles();
  const [button, _setButton] = useState(buttonsArr[1][0].id);
  const [color, _setColor] = useState(colors[0].id);
  const matches = useMediaQuery('(max-width:900px)');

  const onSetColor = (c) => {
    trackEvent('board.canvas.color');
    _setColor(c.id);
    if (c.color) {
      setColor(c.color);
    }
  };

  const onSetButton = (b, option) => {
    trackEvent(`board.canvas.${b.id}`);
    if (b.id === 'text') {
      return setIsText(true);
    }
    _setButton(b.id);
    if (b.radius) {
      setRadius(b.radius);
    }
    if (b.alpha) {
      setAlpha(b.alpha);
    }
    setIsReference(b.id === 'reference');
    setIsShape(b.id === 'shapes');
    setIsEraser(b.id === 'eraser');

    if (b.id === 'eraser') {
      if (option === 'free') {
        trackEvent('board.canvas.eraser.free');
        setAlpha(1);
      } else if (option === 'stroke') {
        trackEvent('board.canvas.eraser.stroke');
        setAlpha(0);
      }
    }
  };

  const _onImageUpload = (path) => {
    trackEvent('board.canvas.imageUploaded');
    onImageUpload(path);
  }

  return (
    <div className={classes.root}>
      {
        buttonsArr.map((buttons) => (

          <div>
            {
              buttons.map((b) => {
                if (b.options) {
                  return (
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Fab
                            color={b.id === button ? 'primary' : 'default'}
                            key={b.id}
                            className={classes.button}
                            size={matches ? 'small' : 'large'}
                            {...bindTrigger(popupState)}>
                            <FontAwesomeIcon
                              size={matches ? 'sm' : 'lg'}
                              icon={b.icon}
                            />
                          </Fab>
                          <Menu {...bindMenu(popupState)}>
                            {
                              map(b.options, (option) => {
                                return (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      onSetButton(b, option);
                                    }}
                                  >
                                    {translate(option)}
                                  </MenuItem>
                                );
                              })
                            }
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  );
                }

                if (b.id === 'image') {
                  return (
                    <label>
                      <Fab
                        component="a"
                        color="default"
                        key={b.id}
                        className={classes.button}
                        size={matches ? 'small' : 'large'}
                      >
                        <FontAwesomeIcon
                          size={matches ? 'sm' : 'lg'}
                          icon={b.icon}
                        />
                      </Fab>
                      <FileUploader
                        hidden
                        accept="image/*"
                        name="avatar"
                        randomizeFilename
                        storageRef={firebase.storage().ref('images')}
                        onUploadSuccess={(image) => _onImageUpload(`images/${image}`)}
                      />
                    </label>
                  );
                }

                return (
                  <Fab
                    color={b.id === button ? 'primary' : 'default'}
                    key={b.id}
                    className={classes.button}
                    onClick={() => onSetButton(b)}
                    size={matches ? 'small' : 'large'}
                  >
                    <FontAwesomeIcon
                      size={matches ? 'sm' : 'lg'}
                      icon={b.icon}
                    />
                  </Fab>
                );
              })
            }
          </div>
        ))
      }
      <div>
        {
          colors.map((c, index) => {
            let className;
            if (matches) {
              className = classes.smallColor;
              if (color === c.id) {
                className = `${className} ${classes.smallSelectedColor}`;
              }
            } else {
              className = classes.color;
              if (color === c.id) {
                className = `${className} ${classes.selectedColor}`;
              }
            }
            return (
              <span
                key={c.id}
                style={{backgroundColor: `rgba(${c.color},1)`}}
                className={className}
                onClick={() => onSetColor(c)}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Controls;
